import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IconService } from "src/app/services/icon.service";
import { NgIf } from "@angular/common";
import { IconComponent } from "../icon/icon.component";
import { TranslateModule } from "@ngx-translate/core";
import { LimiterPipe } from "../../../pipes/limiter.pipe";

@Component({
  selector: "app-view-more",
  templateUrl: "./view-more.component.html",
  standalone: true,
  imports: [NgIf, IconComponent, TranslateModule, LimiterPipe]
})
export class ViewMoreComponent implements OnInit {
  @Input() current: number;
  @Input() total: number;

  @Output() clicked = new EventEmitter<void>();

  constructor(public iconService: IconService) {}

  ngOnInit(): void {}
}
