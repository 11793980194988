import { Pipe, PipeTransform } from "@angular/core";
import { PriceResponse } from "src/domain/client";

@Pipe({
  name: "priceDiffPipe",
  standalone: true
})
export class PriceDiffPipePipe implements PipeTransform {
  transform(price: PriceResponse): string {
    if (!price.discounted || !price.price) return "";
    if (Math.round(price.discounted) == Math.round(price.price)) return "";
    return Math.round(price.price).toString();
  }
}
