import { NgModule } from "@angular/core";

import { NgAddToCalendarService } from "./service/ng-add-to-calendar.service";

@NgModule({
  declarations: [],
  providers: [NgAddToCalendarService],
  exports: []
})
export class NgAddToCalendarModule {}
