import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "limiter",
  standalone: true
})
export class LimiterPipe implements PipeTransform {
  constructor() {}

  transform(current: number, max: number) {
    return "(" + current.toString() + " av " + max.toString() + ")";
  }
}
