import { Component, OnInit } from "@angular/core";
import { FormService } from "src/app/services/form.service";
import { TranslateModule } from "@ngx-translate/core";
import { CarBrandPipe } from "../../../pipes/car-brand.pipe";

@Component({
  selector: "app-service-agreement-package-info",
  templateUrl: "./service-agreement-package-info.component.html",
  standalone: true,
  imports: [TranslateModule, CarBrandPipe]
})
export class ServiceAgreementPackageInfoComponent implements OnInit {
  constructor(public formService: FormService) {}

  ngOnInit(): void {}
}
