import { Pipe, PipeTransform } from "@angular/core";
import { PackageTypeEnum } from "src/domain/client";

@Pipe({
  name: "shouldShowSubmissionOptions",
  standalone: true
})
export class ShouldShowSubmissionOptionsPipe implements PipeTransform {
  transform(subPackageType: PackageTypeEnum): string {
    const submissionTypeMap = {
      [PackageTypeEnum.CheckInOption]: "checkin",
      [PackageTypeEnum.Mobility]: "mobility"
    };

    const submissionType = submissionTypeMap[subPackageType];
    if (!submissionType) {
      return "";
    }
    return `submissionOptions.${submissionType}`;
  }
}
