import { Pipe, PipeTransform } from "@angular/core";
import { DiscountTypeEnum } from "src/domain/client";
import { FormService } from "../services/form.service";

@Pipe({
  name: "discountPrice",
  pure: false,
  standalone: true
})
export class DiscountPricePipe implements PipeTransform {
  constructor(private formService: FormService) {}

  transform(value: number): number {
    let returnValue = 0;
    if (!this.formService.hasValidCampaignCode) return value;
    if (
      this.formService.campaignResponse.discountType === DiscountTypeEnum.Fixed
    ) {
      returnValue = value - this.formService.campaignResponse.discount;
    }
    if (
      this.formService.campaignResponse.discountType ===
      DiscountTypeEnum.Percentage
    ) {
      returnValue = Math.round(
        (1 - this.formService.campaignResponse.discount) * value
      );
    }
    if (returnValue <= 0) returnValue = 0;
    return returnValue;
  }
}
