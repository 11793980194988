import { Component } from "@angular/core";
import { InformationContentComponent } from "../../layout/information-content/information-content.component";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "app-peronal-data",
  templateUrl: "./personal-data.component.html",
  standalone: true,
  imports: [InformationContentComponent, TranslateModule]
})
export class PersonalDataComponent {}
