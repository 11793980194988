import { Pipe, PipeTransform } from "@angular/core";
import { FormService } from "../services/form.service";
import { PackageTagEnum, SelectedPackagesRequest } from "src/domain/client";

@Pipe({
  name: "totalPrice",
  standalone: true
})
export class TotalPricePipe implements PipeTransform {
  constructor(private formService: FormService) {}

  transform(allSelectedPackages: SelectedPackagesRequest[]): number {
    let total = 0;
    const allPackages =
      this.formService.basePackagesResponse?.concat(
        this.formService.additionalPackagesResponse ?? []
      ) ?? [];
    for (const selectedPackage of allSelectedPackages) {
      const packageMatch = allPackages.find(
        (p) =>
          p.id ==
          (selectedPackage.packageId?.toString() ||
            selectedPackage.packageExternalId)
      );
      if (!packageMatch) continue;
      if (packageMatch.tags.includes(PackageTagEnum.ServiceAgreement)) continue;
      total += packageMatch.price?.discounted;
      for (const subpackage of packageMatch.subPackages) {
        if (subpackage.tags.includes(PackageTagEnum.MainPackage)) {
          total += Math.floor(subpackage.price?.discounted);
          continue;
        }
        if (selectedPackage.subPackageIds.includes(subpackage.id)) {
          total += Math.floor(subpackage.price?.discounted);
          continue;
        }
      }
    }
    return total;
  }
}
