// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.no-boxshadow {
  transition: none !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  -webkit-appearance: none !important;
}

@media screen and (max-width: 992px) {
  .mat-mdc-dialog-content.no-padding {
    padding: 0px 0px;
  }
  .mat-mdc-dialog-content.no-padding ~ .mat-mdc-dialog-actions {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
}
.mat-mdc-dialog-actions,
.mat-mdc-dialog-content {
  padding-left: 24px;
  padding-right: 24px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/layout/dialog-will-invalidate-form/dialog-will-invalidate-form.component.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;EACA,2BAAA;EACA,2BAAA;EACA,mCAAA;AACF;;AAEA;EACE;IACE,gBAAA;EACF;EAEA;IACE,eAAA;IACA,gBAAA;IACA,iBAAA;EAAF;AACF;AAEA;;EAEE,kBAAA;EACA,mBAAA;AAAF","sourcesContent":[".no-boxshadow {\n  transition: none !important;\n  box-shadow: none !important;\n  border-radius: 0 !important;\n  -webkit-appearance: none !important;\n}\n\n@media screen and (max-width: 992px) {\n  .mat-mdc-dialog-content.no-padding {\n    padding: 0px 0px;\n  }\n\n  .mat-mdc-dialog-content.no-padding ~ .mat-mdc-dialog-actions {\n    padding-left: 0;\n    padding-right: 0;\n    padding-bottom: 0;\n  }\n}\n.mat-mdc-dialog-actions,\n.mat-mdc-dialog-content {\n  padding-left: 24px;\n  padding-right: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
