import { Injectable } from "@angular/core";
import { ApplicationMessageResponse, Client } from "src/domain/client";

@Injectable({
  providedIn: "root"
})
export class ApplicationMessageService {
  applicationMessages: ApplicationMessageResponse[] = [];

  constructor() {}

  getApplicationMessages(): void {
    new Client()
      .apiV2ASWPActiveApplicationMessages()
      .then((applicationMessages) => {
        this.applicationMessages = applicationMessages;
      })
      .catch(() => {
        // fail silently
      });
  }

  deleteApplicationMessage(id: number): void {
    const index = this.applicationMessages.findIndex(
      (_) => _.applicationMessageId === id
    );
    if (index > -1) {
      this.applicationMessages.splice(index, 1);
    }
  }
}
