import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import {
  CustomerResponseRequest,
  ICustomerResponseRequest,
  PriorityResponse,
  ProposalResponse,
  ServiceLinkClient
} from "src/domain/service-link/serviceLinkClient";

@Injectable({
  providedIn: "root"
})
export class ServiceLinkHttpService {
  private client: ServiceLinkClient = null;

  constructor(private translateService: TranslateService) {
    this.client = new ServiceLinkClient();
  }

  get(id: string): Promise<ProposalResponse> {
    return this.client.apiV1Proposal(id);
  }

  getPriorityList(): Promise<PriorityResponse[]> {
    return this.client.apiV1ProposalPrioritiesAll(
      this.translateService.currentLang
    );
  }

  respond(_proposalId: string, _acceptedItems: number[]): Promise<void> {
    const body = new CustomerResponseRequest({
      proposalId: _proposalId,
      acceptedItemIds: _acceptedItems
    } as ICustomerResponseRequest);

    return this.client.apiV1ProposalRespond(body);
  }

  markOpened(proposalId: string) {
    this.client.apiV1ProposalOpenedByCustomer(proposalId);
  }
}
