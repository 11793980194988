import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { CheckboxComponent } from "../../inputs/checkbox/checkbox.component";
import { NgIf } from "@angular/common";

@Component({
  selector: "app-secondary-confirmation-box",
  templateUrl: "./secondary-confirmation-box.component.html",
  standalone: true,
  imports: [CheckboxComponent, NgIf]
})
export class SecondaryConfirmationBoxComponent implements OnInit {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() checkboxText: string;
  @Input() parentForm: FormGroup<any>;
  @Input() formControlKey: string;
  @Input() link?: string;
  @Input() linkText?: string;

  constructor() {}

  ngOnInit(): void {}
}
