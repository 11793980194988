import {
  animate,
  animateChild,
  group,
  query,
  style,
  transition,
  trigger
} from "@angular/animations";

export const routeChangeAnimation = trigger("routeTransitionAnimations", [
  transition(
    "Step1 => Step2, Step2 => Step3, Step3 => Step4, Step4 => Step5, Step5 => Step6, Step6 => Step7, Step7 => Step8, \
    Step1 => Step3, Step1 => Step4, Step1 => Step5, Step1 => Step6, Step1 => Step7, \
    Step2 => Step4, Step2 => Step5, Step2 => Step6, Step2 => Step7, \
    Step3 => Step5, Step3 => Step6, Step3 => Step7, \
    Step4 => Step6, Step4 => Step7, \
    Step5 => Step7",
    [
      style({ position: "relative", overflow: "hidden" }),
      query(":enter, :leave", [
        style({
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%"
        })
      ]),
      query(":enter", [style({ left: "100%" })]),
      query(":leave", animateChild()),
      group([
        query(":leave", [animate("250ms ease-out", style({ left: "-100%" }))]),
        query(":enter", [animate("250ms ease-out", style({ left: "0%" }))])
      ]),
      query(":enter", animateChild())
    ]
  ),
  transition(
    "Step2 => Step1, Step3 => Step2, Step4 => Step3, Step5 => Step4, Step6 => Step5, Step7 => Step6, Step8 => Step7, \
    Step3 => Step1, Step4 => Step1, Step5 => Step1, Step6 => Step1, Step7 => Step1, Step8 => Step1, \
    Step4 => Step2, Step5 => Step2, Step6 => Step2, Step7 => Step2, Step8 => Step2, \
    Step5 => Step3, Step6 => Step3, Step7 => Step3, Step8 => Step3, \
    Step6 => Step4, Step7 => Step4, Step8 => Step4, \
    Step7 => Step5, Step8 => Step5, \
    Step8 => Step6",
    [
      style({ position: "relative", overflow: "hidden" }),
      query(":enter, :leave", [
        style({
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%"
        })
      ]),
      query(":enter", [style({ left: "-100%" })]),
      query(":leave", animateChild()),
      group([
        query(":leave", [animate("250ms ease-out", style({ left: "100%" }))]),
        query(":enter", [animate("250ms ease-out", style({ left: "0%" }))])
      ]),
      query(":enter", animateChild())
    ]
  )
]);
