import {
  animate,
  state,
  style,
  transition,
  trigger
} from "@angular/animations";

export const expandFooterPopupAnimation = trigger("openClose", [
  state("open", style({ top: "0%", opacity: 1 })),
  state(
    "closed",
    style({
      top: "*",
      opacity: 0
    })
  ),
  transition("open => closed", [animate("0.3s ease-in-out")]),
  transition("closed => open", [animate("0.3s ease-in-out")])
]);
