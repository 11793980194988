import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { expandAnimation } from "src/app/animations/expand";
import { FormService } from "src/app/services/form.service";
import { IconService } from "src/app/services/icon.service";
import { MappingService } from "src/app/services/mapping.service";
import { PackageTagEnum } from "src/domain/client";
import { NgIf, NgFor } from "@angular/common";
import { RadioButtonComponent } from "../../inputs/radio-button/radio-button.component";
import { CheckboxComponent } from "../../inputs/checkbox/checkbox.component";
import { IconComponent } from "../icon/icon.component";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "app-checkbox-card",
  templateUrl: "./checkbox-card.component.html",
  animations: [expandAnimation],
  standalone: true,
  imports: [
    NgIf,
    RadioButtonComponent,
    CheckboxComponent,
    IconComponent,
    NgFor,
    TranslateModule
  ]
})
export class CheckboxCardComponent implements OnInit {
  @Input() id: string;
  @Input() title: string;
  @Input() badges: string[];
  @Input() badgeLabels: string[];
  @Input() subtitle: string;
  @Input() extended: boolean;
  @Input() bodyTitle: string;
  @Input() body: string;
  @Input() rounded?: boolean;
  @Input() radio?: boolean;
  @Input() checked?: boolean;
  @Input() formValue?: string;
  @Input() parentForm: FormGroup<any>;
  @Input() formControlKey: string;
  @Input() icon?: string;

  @Output() radioClicked = new EventEmitter<string>();

  public collapsed: boolean;

  constructor(
    public mappingService: MappingService,
    public iconService: IconService,
    public formService: FormService
  ) {}

  ngOnInit(): void {
    this.collapsed = !this.extended;
  }

  onToggle(): void {
    this.collapsed = !this.collapsed;
  }

  onRadioClick(event: any): void {
    if (this.checked) event.preventDefault();
    this.radioClicked.emit(this.formValue);
  }

  determineBadgeType(badge: PackageTagEnum | string): string {
    switch (badge) {
      case PackageTagEnum.Recommended:
        return "info";
      case PackageTagEnum.Campaign:
        return "primary";
      case "short":
        return "success";
      case "short-tyre":
        return "success";
      case "medium":
        return "warning";
      case "long":
        return "danger";
      default:
        return "";
    }
  }
}
