import { FormGroup, FormControl } from "@angular/forms";
import { FormService } from "src/app/services/form.service";
import {
  PackageResponse,
  PackageTagEnum,
  PackageTypeEnum
} from "src/domain/client";

export function createExtraServicesForm(formService: FormService) {
  const packages = formService.additionalPackagesResponse ?? [];
  const serviceAdditionalPackages = packages.filter((j: PackageResponse) =>
    [PackageTypeEnum.ServiceAdditional, PackageTypeEnum.PPS].includes(j.type)
  );
  const dealerExtraPackages = packages.filter(
    (j: PackageResponse) =>
      j.type === PackageTypeEnum.DealerExtra ||
      j.type === PackageTypeEnum.WashAndRecond
  );
  const newForm = new FormGroup<any>(
    {},
    formService.secureStateOnChanges(formService)
  );
  serviceAdditionalPackages.forEach((p: PackageResponse) => {
    const tmpForm = new FormGroup<any>({});
    tmpForm.addControl(
      "selected",
      new FormControl(p.tags.includes(PackageTagEnum.Recommended))
    );
    if (!p.isSubCategory) {
      p.subPackages.forEach((sp: PackageResponse) => {
        tmpForm.addControl(
          sp.id,
          new FormControl({
            value: false,
            disabled: false
          })
        );
      });
    } else {
      const value = p.subPackages.find((pkg) =>
        pkg.tags.includes(PackageTagEnum.Recommended)
      );

      tmpForm.addControl(
        "subcategoryContainer",
        new FormControl({
          value: value?.id || "",
          disabled: false
        })
      );
    }
    newForm.addControl(p.id, tmpForm);
  });
  dealerExtraPackages.forEach((p: PackageResponse) => {
    const tmpForm = new FormGroup<any>({});
    tmpForm.addControl(
      "selected",
      new FormControl(p.tags.includes(PackageTagEnum.Recommended))
    );
    if (!p.isSubCategory) {
      p.subPackages.forEach((sp: PackageResponse) => {
        tmpForm.addControl(
          sp.id,
          new FormControl({
            value: false,
            disabled: false
          })
        );
      });
    } else {
      // find the selected subpackage if any
      const value = p.subPackages.find((pkg) =>
        pkg.tags.includes(PackageTagEnum.Recommended)
      );

      tmpForm.addControl(
        "subcategoryContainer",
        new FormControl({
          value: value?.id || "",
          disabled: false
        })
      );
    }

    newForm.addControl(p.id, tmpForm);
  });
  return newForm;
}
