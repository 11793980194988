import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { UrlService } from "src/app/services/url.service";
import { MatTooltip } from "@angular/material/tooltip";
import { NgIf } from "@angular/common";
import { MatProgressSpinner } from "@angular/material/progress-spinner";

@Component({
  selector: "app-button",
  templateUrl: "./button.component.html",
  standalone: true,
  imports: [MatTooltip, NgIf, MatProgressSpinner]
})
export class ButtonComponent implements OnInit {
  @Input() text: string;
  @Input() type: "primary" | "secondary";
  @Input() disabled?: boolean;
  @Input() isLoading?: boolean;
  @Input() pulse?: boolean;
  @Input() matchingborderBg?: boolean;
  @Input() tooltipText?: string;

  @Output() clicked$ = new EventEmitter<void>();

  constructor(public urlService: UrlService) {}

  ngOnInit() {}
  onClick = () => this.clicked$.emit();
}
