import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import { IconService } from "src/app/services/icon.service";
import { NgIf } from "@angular/common";
import { MatIcon } from "@angular/material/icon";
import { MatTooltip } from "@angular/material/tooltip";
import { ImageResolverPipe } from "../../../pipes/sharedpipes/image-resolver.pipe";

@Component({
  selector: "app-icon",
  templateUrl: "./icon.component.html",
  standalone: true,
  imports: [NgIf, MatIcon, MatTooltip, ImageResolverPipe]
})
export class IconComponent implements OnInit {
  isInitialized = false;
  isSvg = false;
  isUrl = false;

  @ViewChild("tooltipId") matTooltip;

  @Input() icon: string;
  @Input() class: string;
  @Input() pointer = false;
  @Input() tooltip: string;
  @Output() click$? = new EventEmitter<void>();

  constructor(public iconService: IconService) {}

  ngOnInit(): void {
    this.isSvg = this.iconService.IsSvgIcon(this.icon);
    this.isUrl = this.iconService.IsUrl(this.icon);
    this.isInitialized = true;
  }

  click(): void {
    if (this.tooltip) this.matTooltip.toggle();
    if (this.click$) {
      this.click$.emit();
    }
  }

  handleTouchStart(): void {
    if (this.tooltip) this.matTooltip.show();
  }
  handleTouchEnd(): void {
    if (this.tooltip) this.matTooltip.hide();
  }
}
