import { Component, Output, EventEmitter, Input } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { NgxMaskDirective } from "ngx-mask";
import { NgIf } from "@angular/common";
import { MatIconButton } from "@angular/material/button";
import { MatSuffix } from "@angular/material/form-field";
import { MatIcon } from "@angular/material/icon";
import { ImageResolverPipe } from "../../../pipes/sharedpipes/image-resolver.pipe";
import { ChildControlPipe } from "../../../pipes/child-control.pipe";

@Component({
  selector: "app-registration-number",
  templateUrl: "./registration-number.component.html",
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgxMaskDirective,
    NgIf,
    MatIconButton,
    MatSuffix,
    MatIcon,
    ImageResolverPipe,
    ChildControlPipe
  ]
})
export class RegistrationNumberComponent {
  @Input() id: string;
  @Input() placeholder: string;
  @Input() parentForm: FormGroup;
  @Input() formControlKey: string;

  @Output() confirmed = new EventEmitter();
  constructor() {}

  clear(): void {
    this.parentForm.get(this.formControlKey)?.setValue("");
  }
}
