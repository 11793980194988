import { Pipe, PipeTransform } from "@angular/core";
import { BrandCodeEnum, VehicleSearchResponse } from "src/domain/client";
import { BrandEnum } from "../classes/enums";

@Pipe({
  name: "carBrand",
  standalone: true
})
export class CarBrandPipe implements PipeTransform {
  transform(response: VehicleSearchResponse): BrandEnum | "default" {
    if (!response) {
      return "default";
    }
    switch (response.brandCode) {
      case BrandCodeEnum.V:
        return BrandEnum.vw;
      case BrandCodeEnum.A:
        return BrandEnum.audi;
      case BrandCodeEnum.S:
        if (response.specialBrandCode === "Q") {
          return BrandEnum.cupra;
        }
        return BrandEnum.seat;
      case BrandCodeEnum.C:
        return BrandEnum.skoda;
      case BrandCodeEnum.P:
        return BrandEnum.porsche;
      case BrandCodeEnum.N:
        return BrandEnum.vw;
      default:
        return "default";
    }
  }
}
