import { Component } from "@angular/core";
import { PagingService } from "src/app/services/paging.service";
import { NgIf } from "@angular/common";
import { FooterComponent } from "../footer/footer.component";
import { IsFeatureAppDirective } from "../../../directives/is-feature-app.directive";
import { FooterPopupComponent } from "../footer-popup/footer-popup.component";
import { FooterServiceLinkComponent } from "../footer-service-link/footer-service-link.component";

@Component({
  selector: "app-footer-base",
  templateUrl: "./footer-base.component.html",
  styleUrls: [],
  standalone: true,
  imports: [
    NgIf,
    FooterComponent,
    IsFeatureAppDirective,
    FooterPopupComponent,
    FooterServiceLinkComponent
  ]
})
export class FooterBaseComponent {
  constructor(public pagingService: PagingService) {}
}
