import { Component } from "@angular/core";
import {
  MINIMAL_CONSENT,
  FULL_CONSENT
} from "src/app/services/consent.service";
import { PagingService } from "src/app/services/paging.service";
import { IConsentFormResult } from "src/app/interfaces/interfaces";
import { AbstractControl, FormControl, FormGroup } from "@angular/forms";
import { NgIf } from "@angular/common";
import { CdkScrollable } from "@angular/cdk/scrolling";
import {
  MatDialogContent,
  MatDialogActions,
  MatDialogClose
} from "@angular/material/dialog";
import { ButtonComponent } from "../../inputs/button/button.component";
import { CheckboxComponent } from "../../inputs/checkbox/checkbox.component";
import { RouterLink } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { UrlService } from "src/app/services/url.service";

type ConsentForm = {
  [key in keyof IConsentFormResult]: AbstractControl<IConsentFormResult[key]>;
};

@Component({
  selector: "app-dialog-cookie",
  templateUrl: "./dialog-cookie.component.html",
  standalone: true,
  imports: [
    NgIf,
    CdkScrollable,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    ButtonComponent,
    CheckboxComponent,
    RouterLink,
    TranslateModule
  ]
})
export class DialogCookieComponent {
  MINIMAL = MINIMAL_CONSENT;
  FULL = FULL_CONSENT;

  constructor(
    public pagingService: PagingService,
    public urlService: UrlService
  ) {}

  adjustCookies = false;

  cookieForm = new FormGroup<ConsentForm>({
    required: new FormControl({ value: true, disabled: true }),
    functional: new FormControl(true),
    performance: new FormControl(true),
    marketing: new FormControl(true)
  });
}
