import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  AbstractControl,
  FormGroup,
  ReactiveFormsModule
} from "@angular/forms";
import { NgxMaskDirective } from "ngx-mask";
import { NgIf } from "@angular/common";
import { MatIconButton } from "@angular/material/button";
import { MatSuffix } from "@angular/material/form-field";
import { MatIcon } from "@angular/material/icon";

@Component({
  selector: "app-registration-number-box",
  templateUrl: "./registration-number-box.component.html",
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgxMaskDirective,
    NgIf,
    MatIconButton,
    MatSuffix,
    MatIcon
  ]
})
export class RegistrationNumberBoxComponent implements OnInit {
  @Input() id: string;
  @Input() parentForm: FormGroup<any>;
  @Input() formControlKey: string;
  @Input() placeholder?: string;
  @Input() maxLen?: number;
  @Input() clearable?: boolean;

  @Output() confirm$ = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  clear(): void {
    this.control.setValue("");
  }

  confirm(): void {
    this.confirm$.emit();
  }

  get control(): AbstractControl {
    return this.parentForm.get(this.formControlKey);
  }
}
