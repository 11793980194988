import { Pipe, PipeTransform } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";

@Pipe({
  name: "childControl",
  standalone: true
})
export class ChildControlPipe implements PipeTransform {
  transform(parentForm: FormGroup, key: string): FormControl {
    return parentForm.get(key) as FormControl;
  }
}
