import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { IViewPair, IWindscreenForm } from "src/app/interfaces/interfaces";
import { ExtenderService } from "src/app/services/extender.service";
import { FileService } from "src/app/services/file.service";
import { FormService } from "src/app/services/form.service";
import { IconService } from "src/app/services/icon.service";
import { FileParameter } from "src/domain/client";
import { NgIf } from "@angular/common";
import { DropdownComponent } from "../../inputs/dropdown/dropdown.component";
import {
  MatFormField,
  MatLabel,
  MatSuffix
} from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import {
  MatDatepickerInput,
  MatDatepickerToggle,
  MatDatepickerToggleIcon,
  MatDatepicker
} from "@angular/material/datepicker";
import { MatIcon } from "@angular/material/icon";
import { TextAreaComponent } from "../../inputs/text-area/text-area.component";
import { IconComponent } from "../icon/icon.component";
import { ButtonComponent } from "../../inputs/button/button.component";
import { FirstToUpperPipe } from "../../../pipes/sharedpipes/first-to-upper.pipe";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "app-damage-report",
  templateUrl: "./damage-report.component.html",
  standalone: true,
  imports: [
    NgIf,
    DropdownComponent,
    MatFormField,
    ReactiveFormsModule,
    MatLabel,
    MatInput,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatSuffix,
    MatIcon,
    MatDatepickerToggleIcon,
    MatDatepicker,
    TextAreaComponent,
    IconComponent,
    ButtonComponent,
    FirstToUpperPipe,
    TranslateModule
  ]
})
export class DamageReportComponent implements OnInit {
  public todayDate = new Date();
  public fileId = null;

  @Input() insuranceViewPairs: IViewPair[];

  @ViewChild("windscreen_file_upload") fileUploadElementRef: ElementRef;

  constructor(
    public formService: FormService,
    public extenderService: ExtenderService,
    public iconService: IconService,
    private fileService: FileService
  ) {}

  ngOnInit(): void {}

  get windscreenForm(): FormGroup<IWindscreenForm> {
    return this.formService.chooseSpecificationsForm.controls.windscreenForm;
  }

  onWindscreenFileChanged(event: any): void {
    if (event.target.files?.length) {
      const [newFile] = event.target.files;
      if (newFile.size > 30000000) {
        this.windscreenForm.get("file").setErrors({ large: true });
        return;
      }

      // Reads the file into a FileParameter and also converts image to blob
      const reader = new FileReader();
      reader.onload = () => {
        const fileParameter: FileParameter = {
          data: new Blob([reader.result as ArrayBuffer], {
            type: newFile.type
          }),
          fileName: newFile.name
        };
        this.fileService
          .uploadFile(fileParameter)
          .then((_) => (this.fileId = _));
      };
      this.windscreenForm.patchValue({
        file: newFile
      });
      reader.readAsArrayBuffer(newFile);
    }
  }
  onWindscreenFileClear(): void {
    this.windscreenForm.patchValue({
      file: null
    });
    this.fileService.clearFile(this.fileId);
  }

  handleFileUpload(): void {
    this.fileUploadElementRef.nativeElement.click();
  }
}
