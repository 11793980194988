import { Injectable } from "@angular/core";
import { UrlService } from "../../../services/url.service";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { ServiceLinkStoreService } from "./service-link-store.service";
import { ErrorService } from "./error.service";

@Injectable({
  providedIn: "root"
})
export class ServiceLinkPagingService {
  constructor(
    private router: Router,
    private urlService: UrlService,
    private location: Location,
    private serviceLinkStoreService: ServiceLinkStoreService,
    private errorService: ErrorService
  ) {}

  get isError() {
    return this.location.path().includes("error");
  }

  get isStartStep(): boolean {
    return this.location.path().includes("start");
  }

  get isSummaryStep(): boolean {
    return this.location.path().includes("summary");
  }

  get isCompletedStep(): boolean {
    return this.location.path().includes("completed");
  }

  navigateBack() {
    return this.router.navigate([
      this.urlService.getBasePath() + "/service-link/start"
    ]);
  }

  private navigateOrderStep() {
    return this.router.navigate([
      this.urlService.getBasePath() + "/service-link/completed"
    ]);
  }

  private navigateSummaryStep() {
    return this.router.navigate([
      this.urlService.getBasePath() + "/service-link/summary"
    ]);
  }

  public navigateError(errorMsg: string) {
    this.errorService.setError(errorMsg);
    this.router.navigate([
      this.urlService.getBasePath() + "/service-link/error"
    ]);
  }

  async navigateForward(anyTrue: boolean) {
    if (this.isStartStep) {
      await this.navigateSummaryStep();
    } else if (this.isSummaryStep) {
      if (anyTrue) {
        await this.serviceLinkStoreService.respond();
      } else {
        await this.serviceLinkStoreService.respondNotNow();
      }
      await this.navigateOrderStep();
    }
  }
}
