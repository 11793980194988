import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class ErrorService {
  private errorMessage: string | null = "service-link.error.defaultMsg";
  constructor() {}

  setError(message: string) {
    this.errorMessage = message;
  }

  getError(): string | null {
    return this.errorMessage;
  }
}
