import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IconService } from "src/app/services/icon.service";
import { NgIf } from "@angular/common";
import { IconComponent } from "../icon/icon.component";

@Component({
  selector: "app-section-toggler",
  templateUrl: "./section-toggler.component.html",
  standalone: true,
  imports: [NgIf, IconComponent]
})
export class SectionTogglerComponent implements OnInit {
  @Input() icon?: string;
  @Input() title: string;
  @Input() isCollapsed: boolean;
  @Input() disableToggle: boolean;
  @Output() toggle$ = new EventEmitter<void>();

  constructor(public iconService: IconService) {}

  ngOnInit(): void {}

  onToggle(): void {
    this.toggle$.emit();
  }
}
