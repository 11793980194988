import { Component, OnInit } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogTitle,
  MatDialogClose,
  MatDialogContent,
  MatDialogActions
} from "@angular/material/dialog";
import { Inject } from "@angular/core";
import { DialogTypeEnum } from "src/app/classes/enums";
import { IDialogData } from "src/app/interfaces/interfaces";
import { IconService } from "src/app/services/icon.service";
import { DialogCookieComponent } from "./dialog-cookie.component";
import { UrlService } from "src/app/services/url.service";
import { NgIf, NgSwitch, NgSwitchCase } from "@angular/common";
import { IconComponent } from "../icon/icon.component";
import { CdkScrollable } from "@angular/cdk/scrolling";
import { SummaryComponent } from "../summary/summary.component";
import { ButtonComponent } from "../../inputs/button/button.component";
import { TranslateModule } from "@ngx-translate/core";

export const dialogComponentList = [DialogCookieComponent];

@Component({
  selector: "app-dialog",
  templateUrl: "./dialog.component.html",
  standalone: true,
  imports: [
    NgIf,
    MatDialogTitle,
    MatDialogClose,
    IconComponent,
    NgSwitch,
    NgSwitchCase,
    CdkScrollable,
    MatDialogContent,
    SummaryComponent,
    MatDialogActions,
    ButtonComponent,
    DialogCookieComponent,
    TranslateModule
  ]
})
export class DialogComponent implements OnInit {
  public DialogTypeEnum: typeof DialogTypeEnum = DialogTypeEnum;

  constructor(
    private dialogRef: MatDialogRef<DialogComponent>,
    public urlService: UrlService,
    public iconService: IconService,
    @Inject(MAT_DIALOG_DATA) public data: IDialogData
  ) {}
  htmlContent: string;

  ngOnInit(): void {}
  onClose(): void {
    this.dialogRef.close();
  }
}
