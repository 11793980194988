import { Injectable } from "@angular/core";
import { CookieKeys } from "src/domain/constants";
import { CookieService } from "./cookie.service";
import { GoogleConsentType } from "src/domain/GoogleConsentMode/enums";
import { EventTagUnion } from "src/app/interfaces/gtm-interfaces";
import { IConsentFormResult } from "../interfaces/interfaces";
import { ConsentFormCategory } from "../enums/ConsentFormCategory";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { Observable, Subscriber } from "rxjs";
import { DialogComponent } from "../components/layout/dialog/dialog.component";
import { DialogTypeEnum } from "../classes/enums";
import { MatDialog } from "@angular/material/dialog";

type CamelCase<T extends string> = T extends `${infer Prefix}_${infer Suffix}`
  ? `${Capitalize<Prefix>}${CamelCase<Suffix>}`
  : Capitalize<T>;

type ConsentState = Record<ConsentFormCategory, boolean | null>;

type ConsentDTO = Record<GoogleConsentType, "granted" | "denied">;

export const MINIMAL_CONSENT: IConsentFormResult = {
  required: true,
  functional: false,
  performance: false,
  marketing: false
};

export const FULL_CONSENT: IConsentFormResult = {
  required: true,
  functional: true,
  performance: true,
  marketing: true
};

export enum ConsentServiceState {
  LOADING = "loading",
  READY = "ready"
}

@Injectable({
  providedIn: "root"
})
export class ConsentService {
  private consentState: ConsentState = {
    required: null,
    functional: null,
    marketing: null,
    performance: null
  };
  public get hasRequiredConsent() {
    return this.consentState.required ?? false;
  }
  public get hasFunctionalConsent() {
    return this.consentState.functional ?? false;
  }
  public get hasMarketingConsent() {
    return this.consentState.marketing ?? false;
  }
  public get hasPerformanceConsent() {
    return this.consentState.performance ?? false;
  }

  private getConsentFromCookie(
    consentType: ConsentFormCategory
  ): boolean | null {
    const cookieValue = this.cookieService.getCookie(
      `${CookieKeys.consentPrefix}${consentType}`
    );
    if (cookieValue === "0") return false;
    if (cookieValue === "1") return true;
    return null;
  }

  /**
   * Google Tag Manager does black magic when passed an {@link IArguments}
   * object, hence the need for this wrapper function.
   */
  private gtag(...args: any): void;
  private gtag() {
    window.dataLayer.push(arguments);
  }

  private gtagConsent(action: "default" | "update", dto: ConsentDTO) {
    if (window.dataLayer === undefined) {
      window.dataLayer = [];
    }
    this.gtag("consent", action, {
      ...dto,
      ...(action === "default" ? { wait_for_update: 500 } : {})
    });
  }

  public readonly state$: Observable<ConsentServiceState>;

  private _state = ConsentServiceState.LOADING;

  private readonly _subscribers = new Set<Subscriber<ConsentServiceState>>();

  public get state() {
    return this._state;
  }

  private setState(value: ConsentServiceState) {
    this._state = value;
    this._subscribers.forEach((s) => s.next(value));
  }

  constructor(
    private cookieService: CookieService,
    private gtmService: GoogleTagManagerService,
    private dialog: MatDialog
  ) {
    this.state$ = new Observable<ConsentServiceState>((subscriber) => {
      this._subscribers.add(subscriber);
      return () => {
        this._subscribers.delete(subscriber);
      };
    });
    if (typeof FEATURE_APP_BASE_URL !== "undefined" && !!FEATURE_APP_BASE_URL)
      return;
    this.gtagConsent(
      "default",
      ConsentService.stateToConsentDTO(this.consentState)
    );
    const restoredState: ConsentState = {
      required: this.getConsentFromCookie(ConsentFormCategory.REQUIRED) ?? null,
      functional:
        this.getConsentFromCookie(ConsentFormCategory.FUNCTIONAL) ?? null,
      performance:
        this.getConsentFromCookie(ConsentFormCategory.PERFORMANCE) ?? null,
      marketing:
        this.getConsentFromCookie(ConsentFormCategory.MARKETING) ?? null
    };
    this.consent(restoredState).then(
      () => {
        this.setState(ConsentServiceState.READY);
      },
      (err) => {
        this.setState(ConsentServiceState.READY);
        return Promise.reject(err);
      }
    );
  }

  private async consent(consent?: ConsentState): Promise<ConsentState> {
    if (consent) {
      (
        Object.entries(consent) as (readonly [
          keyof typeof consent,
          (typeof consent)[keyof typeof consent]
        ])[]
      ).forEach(([type, value]) => {
        this.consentState[type] = value;
      });
      if (
        this.gtmEnabled &&
        this.gtmService.googleTagManagerId !== "CHANGE-ME"
      ) {
        await this.gtmService.addGtmToDom();
        this.gtagConsent(
          "update",
          ConsentService.stateToConsentDTO(this.consentState)
        );
      }
    }
    return Object.assign({}, this.consentState);
  }

  private static stateToConsentDTO(consentForm: ConsentState): ConsentDTO {
    return {
      required: consentForm.required === true ? "granted" : "denied",
      ad_personalization: consentForm.marketing === true ? "granted" : "denied",
      ad_storage: consentForm.marketing === true ? "granted" : "denied",
      ad_user_data: consentForm.marketing === true ? "granted" : "denied",
      analytics_storage:
        consentForm.performance === true ? "granted" : "denied",
      functionality_storage:
        consentForm.functional === true ? "granted" : "denied",
      personalization_storage:
        consentForm.functional === true ? "granted" : "denied",
      security_storage: consentForm.functional === true ? "granted" : "denied"
    };
  }

  public async consentFromForm(
    consentForm: IConsentFormResult
  ): Promise<ConsentState> {
    (
      Object.entries(consentForm) as (readonly [
        keyof typeof consentForm,
        (typeof consentForm)[keyof typeof consentForm]
      ])[]
    ).forEach(([type, value]) => {
      if (typeof value === "boolean") {
        this.cookieService.setCookie(
          `${CookieKeys.consentPrefix}${type}`,
          value ? "1" : "0",
          365
        );
      }
    });
    const { required, functional, marketing, performance } = consentForm;
    return this.consent({
      required,
      functional,
      marketing,
      performance
    });
  }

  get gtmEnabled() {
    return Object.entries(this.consentState).some(([key, value]) => {
      if (key === "required") return false;
      return value === true;
    });
  }

  isTrackingEventAllowed(item: EventTagUnion): boolean;
  isTrackingEventAllowed() {
    if (!this.gtmEnabled) return false;
    return true;
  }

  get hotjarEnabled() {
    return this.hasPerformanceConsent;
  }

  public openConsentDialog(
    onClose: (results: IConsentFormResult) => void | null = null
  ): void {
    const ref = this.dialog.open(DialogComponent, {
      data: {
        componentName: DialogTypeEnum.cookie,
        title: ""
      },
      panelClass: "custom-dialog-container",
      minWidth: "30vw",
      maxWidth: "800px",
      minHeight: "20vh",
      disableClose: true,
      autoFocus: true
    });

    ref.afterClosed().subscribe({
      next: (result: IConsentFormResult | null) => {
        if (result) {
          this.consentFromForm(result);
        }

        onClose?.(result);
      }
    });
  }
}

ConsentService.prototype satisfies Readonly<
  Record<`has${CamelCase<ConsentFormCategory>}Consent`, boolean>
>;
