import { Injectable } from "@angular/core";
import {
  PackageResponse,
  PackageTagEnum,
  PriceResponse,
  PriceTypeEnum,
  SelectedPackagesRequest
} from "src/domain/client";

@Injectable({
  providedIn: "root"
})
export class PriceService {
  constructor() {}

  public determinePrice(price: PriceResponse): number {
    if (!price) return 0;
    const pricing = price.discounted ? price.discounted : price.price;
    return Math.floor(pricing);
  }

  public getPriceOfPackage(
    pkg: PackageResponse,
    selectedPackages: SelectedPackagesRequest[],
    includeSubpackages: boolean = true
  ): number {
    if (pkg.tags.includes(PackageTagEnum.ServiceAgreement)) return 0;
    const currentSelection = selectedPackages.find(
      (s) => (s.packageId?.toString() || s.packageExternalId) === pkg.id
    );
    if (!pkg.subPackages.length) return pkg.price.discounted;
    const packagePrice = this.determinePrice(pkg.price);
    const subPackagesPrice = pkg.subPackages
      .map((p) => {
        return p.tags.includes(PackageTagEnum.Removable)
          ? currentSelection && currentSelection.subPackageIds.includes(p.id)
            ? this.determinePrice(p.price)
            : 0
          : this.determinePrice(p.price);
      })
      .reduce((a, b) => a + b);
    return includeSubpackages ? subPackagesPrice + packagePrice : packagePrice;
  }

  public getNonDiscountedPriceOfPackage(
    pkg: PackageResponse,
    selectedPackages: SelectedPackagesRequest[]
  ): number {
    const currentSelection = selectedPackages.find(
      (s) => (s.packageId?.toString() || s.packageExternalId) === pkg.id
    );
    if (!pkg.subPackages.length) return pkg.price.price;
    return pkg.subPackages
      .map((p) => {
        return p.tags.includes(PackageTagEnum.Removable)
          ? !!currentSelection && currentSelection.subPackageIds.includes(p.id)
            ? Math.floor(p.price?.price)
            : 0
          : Math.floor(p.price?.price);
      })
      .reduce((a, b) => a + b);
  }

  public determinePriceType(pkg: PackageResponse): PriceTypeEnum {
    const numberOfOffer = pkg.subPackages.filter(
      (p) => p.price.type === PriceTypeEnum.Offer
    ).length;
    if (!!numberOfOffer || pkg.price?.type == PriceTypeEnum.Offer)
      return PriceTypeEnum.Offer;
    const numberOfDeduct = pkg.subPackages.filter(
      (p) => p.price.type === PriceTypeEnum.Deductibles
    ).length;
    if (!!numberOfDeduct || pkg.price?.type == PriceTypeEnum.Deductibles) {
      return PriceTypeEnum.Deductibles;
    }
    const noFr = pkg.subPackages.filter(
      (p) => p.price.type === PriceTypeEnum.From
    ).length;
    if (!!noFr || pkg.price?.type == PriceTypeEnum.From) {
      return PriceTypeEnum.From;
    }
    return PriceTypeEnum.Fixed;
  }
}
