import { Injectable } from "@angular/core";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import {
  DealerResponse,
  PackageResponse,
  VehicleSearchResponse
} from "src/domain/client";
import {
  EventTagUnion,
  IAddToCartEcomTag,
  IBookingDetailsEventTag,
  IChosenAddonEventTag,
  IDataLayerDimensions,
  IDateSelectedEventTag,
  IFormStartedEventTag,
  IFormSubmittedEventTag,
  IMilageChangedEventTag,
  IPageNavigationEventTag,
  IProduct,
  IPurchaseCompleteEcomTag,
  IRemoveFromCartEcomTag,
  ISelectCarEventTag,
  IServiceCategoryEventTag,
  IServiceTypeEventTag,
  IViewItemListEcomTag,
  IWorkshopChosenEventTag
} from "../interfaces/gtm-interfaces";
import { ConsentService, ConsentServiceState } from "./consent.service";
import { v4 as uuidv4 } from "uuid";
import * as moment from "moment";
import { FeatureAppService } from "./feature-app.service";

@Injectable({
  providedIn: "root"
})
export class TrackingService {
  hasTrackedServiceTypesOnce = false;
  hasTrackedAddonsOnce = false;
  clientId = uuidv4();
  shoppingCart: IProduct[] = [];

  private _cache: (["eventTag", EventTagUnion] | ["dataLayer", any])[] = [];

  constructor(
    private gtmService: GoogleTagManagerService,
    private consentService: ConsentService,
    private featureAppService: FeatureAppService
  ) {
    if (gtmService.googleTagManagerId === "CHANGE-ME") {
      console.warn(
        "Google Tag Manager is not properly configured. Please change id form 'CHANGE-ME'. Replacing tag functions with dummy logger..."
      );
      this.pushTag = function pushTag(item: EventTagUnion) {
        if (this.consentService.isTrackingEventAllowed(item)) {
          console.log("GTM Tag Pushed (DUMMY MODE):\n%o", item);
          return Promise.resolve();
        } else {
          console.log("GTM Tag Rejected by consentService:\n%o", item);
          return Promise.resolve();
        }
      };
    }
    consentService.state$.subscribe((value) => {
      if (value === ConsentServiceState.READY) {
        const toPush = this._cache;
        this._cache = [];
        toPush.forEach((el) => {
          switch (el[0]) {
            case "eventTag":
              this.pushTag(el[1]);
              break;
            case "dataLayer":
              this.pushDataLayer(el[1]);
              break;
          }
        });
      }
    });
  }

  pushDataLayer(data: any) {
    if (this.consentService.state === ConsentServiceState.LOADING) {
      this._cache.push(["dataLayer", data]);
      return;
    }
    if (this.consentService.gtmEnabled) window.dataLayer.push(data);
  }

  pushTag(item: EventTagUnion): Promise<void> {
    if (this.consentService.state === ConsentServiceState.LOADING) {
      this._cache.push(["eventTag", item]);
      return;
    }
    if (this.consentService.isTrackingEventAllowed(item)) {
      console.log("GTM Tag Pushed:\n%o", item);
      return this.gtmService.pushTag(item);
    } else {
      console.log("GTM Tag Rejected by consentService:\n%o", item);
      return Promise.resolve();
    }
  }

  updateCustomDimensions(
    selectedWorkshop: DealerResponse,
    carInfo: VehicleSearchResponse,
    milage: number,
    serviceTypeSelection: string[],
    selectedBasePackages: IProduct[],
    bookedDate: Date,
    usedRegnr: boolean,
    dropoffOption: PackageResponse,
    fuelType: string,
    insuranceProviderName?: string
  ): void {
    const trackingObj: IDataLayerDimensions = {
      brand: carInfo
        ? carInfo.brandName.charAt(0).toUpperCase() + carInfo.brandName.slice(1)
        : null,
      dealer_name: selectedWorkshop ? selectedWorkshop.name : null,
      dealer_id: selectedWorkshop ? selectedWorkshop.number : null,
      model: carInfo ? carInfo.modelVariantName : null,
      car_year: carInfo ? carInfo.modelYear : null,
      selected_milage: milage,
      has_service_agreement: carInfo
        ? carInfo.hasServiceAgreement
          ? "Yes"
          : "No"
        : null,

      segment: carInfo
        ? "Segm." + carInfo.originalVehicleSegment.toString()
        : null,
      fueltype: fuelType,
      is_company_vehicle: carInfo
        ? carInfo.isCompanyVehicle
          ? "Yes"
          : "No"
        : null,
      selected_services: serviceTypeSelection.join(","),
      insurance_provider: insuranceProviderName,
      booked_date: bookedDate
        ? moment(bookedDate).format("YYYY MM DD")
        : undefined,
      delivery_method: dropoffOption?.name,
      search_method: carInfo
        ? usedRegnr == true
          ? "Regnumber"
          : "Vinnumber"
        : null,
      selected_services_details: selectedBasePackages
        .map((x) => x.item_name)
        .join(",")
    };
    if (this.featureAppService.isFeatureApp)
      return this.featureAppPushTrackingEvents(
        "custom_dimensions",
        trackingObj
      );
    this.pushDataLayer(trackingObj);
  }

  trackPageChange(url: string, title: string): void {
    const trackingObj: IPageNavigationEventTag = {
      event: "page_view",
      page_url: url,
      page_title: title
    };
    if (this.featureAppService.isFeatureApp)
      return this.featureAppPushTrackingEvents("page_change", trackingObj);
    this.pushTag(trackingObj);
  }

  trackBookingDetailsOpen(totalPrice: number, allProducts: IProduct[]): void {
    const trackingObj: IBookingDetailsEventTag = {
      event: "view_cart",
      ecommerce: {
        currency: "SEK",
        value: totalPrice,
        items: allProducts
      }
    };

    if (this.featureAppService.isFeatureApp)
      return this.featureAppPushTrackingEvents(
        "booking_details_open",
        trackingObj
      );
    console.log("Pushed BookingDetailsEventTag");
    this.pushTag(trackingObj);
  }

  trackSelectCar(
    usedRegNr: boolean,
    model: string,
    brand: string,
    carYear: string,
    insuranceProvider: string,
    hasServiceAgreement: boolean,
    isCompanyVehicle: boolean,
    segment: string,
    fueltype: string
  ): void {
    const trackingObj: ISelectCarEventTag = {
      event: "selected_model",
      search_method: usedRegNr ? "Regnumber" : "Vinnumber",
      model,
      brand,
      car_year: carYear,
      insurance_provider: insuranceProvider,
      has_service_agreement: hasServiceAgreement ? "Yes" : "No",
      is_company_vehicle: isCompanyVehicle ? "Yes" : "No",
      segment,
      fueltype
    };
    if (this.featureAppService.isFeatureApp)
      return this.featureAppPushTrackingEvents("car_selected", trackingObj);
    console.log("Pushed carModel Tag");
    this.pushTag(trackingObj);
  }

  trackMilageSelected(milage: number): void {
    const trackingObj: IMilageChangedEventTag = {
      event: "selected_mileage",
      selected_mileage: milage.toString()
    };
    if (this.featureAppService.isFeatureApp)
      return this.featureAppPushTrackingEvents("milage_selected", trackingObj);
    console.log("Pushed selectMilage Tag");
    this.pushTag(trackingObj);
  }

  trackServiceCategoriesChosen(types: string[]): void {
    const trackingObj: IServiceCategoryEventTag = {
      event: "selected_service",
      selected_services: types.join(",")
    };
    if (this.featureAppService.isFeatureApp)
      return this.featureAppPushTrackingEvents(
        "service_categories_chosen",
        trackingObj
      );
    console.log("Pushed service category Tag");
    (window as any).dataLayer.push({
      label: undefined
    });
    this.pushTag(trackingObj);
  }

  trackWorkshopChosen(
    selectedWorkshop: DealerResponse,
    hasSearched: boolean
  ): void {
    const trackingObj: IWorkshopChosenEventTag = {
      event: "selected_dealer",
      selection_method: hasSearched ? "Search" : "From list",
      dealer_name: selectedWorkshop.name,
      dealer_id: selectedWorkshop.number.toString()
    };
    if (!selectedWorkshop) return; // DONT TRACK IF NON SELECTED
    if (this.featureAppService.isFeatureApp)
      return this.featureAppPushTrackingEvents("dealer_chosen", trackingObj);
    console.log("Pushed retailer Tag");
    this.pushTag(trackingObj);
  }

  trackServiceTypeChosen(
    edited: boolean,
    parentPackages: PackageResponse[],
    selectedPackageChildren: PackageResponse[]
  ): void {
    const trackingObj: IServiceTypeEventTag = {
      event: "selected_service_details",
      selection_method: edited ? "Manual selection" : "Prechosen selection",
      selected_services_details: parentPackages.map((x) => x.name).join(","),
      selected_service_details_add_on: selectedPackageChildren
        .map((x) => x.name)
        .join(",")
    };
    this.hasTrackedServiceTypesOnce = true;
    if (this.featureAppService.isFeatureApp)
      return this.featureAppPushTrackingEvents(
        "service_type_chosen",
        trackingObj
      );
    console.log("Pushed service type Tag");
    (window as any).dataLayer.push({
      label: undefined
    });
    this.pushTag(trackingObj);
  }

  trackAddonChosen(addons: IProduct[]): void {
    const trackingObj: IChosenAddonEventTag = {
      event: "selected_add_on",
      selected_add_on: addons.map((x) => x.item_name).join(",")
    };
    if (this.featureAppService.isFeatureApp)
      return this.featureAppPushTrackingEvents("addon_chosen", trackingObj);
    console.log("Pushed addon chosen event tag");
    this.hasTrackedAddonsOnce = true;
    (window as any).dataLayer.push({
      label: undefined
    });
    this.pushTag(trackingObj);
  }

  trackDateSelected(date: Date, dropoffName: string, addons: string[]): void {
    const trackingObj: IDateSelectedEventTag = {
      event: "chosen_date",
      booked_date: moment(date).format("YYYY MM DD"),
      delivery_method: dropoffName,
      delivery_method_add_on: addons.join(",")
    };
    if (this.featureAppService.isFeatureApp)
      return this.featureAppPushTrackingEvents("date_selected", trackingObj);
    console.log("Pushed date selected event tag");
    this.pushTag(trackingObj);
  }

  trackContactFormStarted(): void {
    const trackingObj: IFormStartedEventTag = {
      event: "contact_form_started"
    };
    if (this.featureAppService.isFeatureApp)
      return this.featureAppPushTrackingEvents(
        "contact_form_started",
        trackingObj
      );
    console.log("Pushed Contact form started event tag");
    this.pushTag(trackingObj);
  }

  trackUpdatedProducts(url: string, allSelectedPackages: IProduct[]): void {
    if (
      !(
        url.includes("form/5") ||
        url.includes("form/6") ||
        url.includes("form/7")
      )
    )
      return;
    const removedProducts = this.shoppingCart.filter(
      (x) => !allSelectedPackages.map((z) => z.item_id).includes(x.item_id)
    );
    this.shoppingCart = this.shoppingCart.filter(
      (x) => !removedProducts.map((z) => z.item_id).includes(x.item_id)
    );
    const newProducts = allSelectedPackages.filter(
      (x) => !this.shoppingCart.map((z) => z.item_id).includes(x.item_id)
    );
    this.shoppingCart = this.shoppingCart.concat(newProducts);
    if (newProducts?.length) {
      this.trackAddToCartEcom(
        newProducts.map((x) => x.price).reduce((partial, x) => partial + x, 0),
        newProducts
      );
    }
    if (removedProducts.length) {
      this.trackRemoveFromCartEcom(
        removedProducts
          .map((x) => x.price)
          .reduce((partial, x) => partial + x, 0),
        removedProducts
      );
    }
  }

  // add to cart på nästa
  trackAddToCartEcom(totalPrice: number, addedPackages: IProduct[]): void {
    const trackingObj: IAddToCartEcomTag = {
      event: "add_to_cart",
      ecommerce: {
        currency: "SEK",
        value: totalPrice,
        items: addedPackages
      }
    };
    if (this.featureAppService.isFeatureApp)
      return this.featureAppPushTrackingEvents("add_to_cart", trackingObj);
    console.log("Pushed add to cart ecom event tag");
    this.pushTag(trackingObj);
  }

  trackRemoveFromCartEcom(
    totalPrice: number,
    removedPackages: IProduct[]
  ): void {
    const trackingObj: IRemoveFromCartEcomTag = {
      event: "remove_from_cart",
      ecommerce: {
        currency: "SEK",
        value: totalPrice,
        items: removedPackages
      }
    };
    if (this.featureAppService.isFeatureApp)
      return this.featureAppPushTrackingEvents("remove_from_cart", trackingObj);
    console.log("Pushed remove from cart ecom event tag");
    this.pushTag(trackingObj);
  }

  // när item list visas, inte hover
  trackItemListViewed(listName: string, packages: IProduct[]): void {
    const trackingObj: IViewItemListEcomTag = {
      event: "view_item_list",
      ecommerce: {
        item_list_id: "123ABC",
        item_list_name: listName,
        items: packages
      }
    };
    if (this.featureAppService.isFeatureApp)
      return this.featureAppPushTrackingEvents("item_list_viwed", trackingObj);
    console.log("Pushed item list hovered ecom event tag");
    this.pushTag(trackingObj);
  }

  trackPurchaseCompleteEcom(
    totalPrice: number,
    products: IProduct[],
    orderId: string,
    couponCode?: string
  ): void {
    const trackingObj: IPurchaseCompleteEcomTag = {
      event: "purchase",
      ecommerce: {
        transaction_id: orderId,
        value: totalPrice,
        currency: "SEK",
        coupon: couponCode,
        items: products
      }
    };
    if (this.featureAppService.isFeatureApp)
      return this.featureAppPushTrackingEvents(
        "purchase_complete",
        trackingObj
      );
    console.log("Pushed purchase complete tag");
    this.pushTag(trackingObj);
    this.resetDataLayer();
  }

  trackContactFormSubmitted(useSms: boolean): void {
    const trackingObj: IFormSubmittedEventTag = {
      event: "contact_form_submit",
      confirmation_type: useSms ? "SMS,Mail" : "Mail"
    };
    if (this.featureAppService.isFeatureApp)
      return this.featureAppPushTrackingEvents(
        "contact_form_submitted",
        trackingObj
      );
    console.log("Pushed contact form submitted tag");
    this.pushTag(trackingObj);
  }

  resetDataLayer(): void {
    const payload: IDataLayerDimensions = {
      brand: undefined,
      dealer_name: undefined,
      dealer_id: undefined,
      model: undefined,
      car_year: undefined,
      selected_milage: undefined,
      has_service_agreement: undefined,
      segment: undefined,
      fueltype: undefined,
      is_company_vehicle: undefined,
      selected_services: undefined,
      insurance_provider: undefined,
      booked_date: undefined,
      delivery_method: undefined,
      search_method: undefined,
      selected_services_details: undefined
    };
    (window as any).dataLayer.push(payload);
  }

  featureAppPushTrackingEvents(trackingId: string, obj: any): void {
    obj.trackingId = trackingId;
    window.postMessage(obj, "*");
    // console.log("Feature app tracked: " + JSON.stringify(obj));
  }
}
