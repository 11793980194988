import { Location } from "@angular/common";
import { DOCUMENT } from "@angular/common";
import { FeatureAppService } from "./feature-app.service";
import { BrandEnum } from "../classes/enums";
import { Inject, Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class UrlService {
  constructor(
    @Inject(DOCUMENT) private _document: HTMLDocument,
    private location: Location,
    private featureAppService: FeatureAppService
  ) {}

  get brand(): BrandEnum | "default" {
    const featureAppBrand = this.featureAppService.getFeatureAppBrand();
    if (featureAppBrand) return featureAppBrand;
    const pathsList = this.location.path().split("/");
    if (pathsList.includes("vw")) return BrandEnum.vw;
    if (pathsList.includes("audi")) return BrandEnum.audi;
    if (pathsList.includes("seat")) return BrandEnum.seat;
    if (pathsList.includes("skoda")) return BrandEnum.skoda;
    if (pathsList.includes("cupra")) return BrandEnum.cupra;
    if (pathsList.includes("porsche")) return BrandEnum.porsche;
    if (this._document.location.host.includes("volkswagen"))
      return BrandEnum.vw;
    if (this._document.location.host.includes("skoda")) return BrandEnum.skoda;
    if (this._document.location.host.includes("seat")) return BrandEnum.seat;
    if (this._document.location.host.includes("audi")) return BrandEnum.audi;
    if (this._document.location.host.includes("cupra")) return BrandEnum.cupra;
    return "default";
  }

  public getBasePath(): string {
    return (
      "/" + (this.featureAppService.getFeatureAppSubPath() ?? "") + this.brand
    );
  }
}
