import { Component } from "@angular/core";
import { Subscription } from "rxjs";

export enum ComponentStateEnum {
  Created,
  Loading,
  Initialized,
  InitializationFailed,
  CallingApi,
  ApiCallFailed
}

@Component({
  selector: "app-page-base",
  template: "",
  standalone: true
})
export class PageBaseComponent {
  state = ComponentStateEnum.Created;
  ComponentStateEnum = ComponentStateEnum;
  subscription = new Subscription();
  constructor() {}

  cleanUp(): void {
    this.subscription.unsubscribe();
  }
}
