import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import {
  ITroubleshootingForm,
  IWindscreenForm
} from "src/app/interfaces/interfaces";
import { FormService } from "src/app/services/form.service";
import { MappingService } from "src/app/services/mapping.service";
import { PagingService } from "src/app/services/paging.service";
import { PriceService } from "src/app/services/price.service";
import { UrlService } from "src/app/services/url.service";
import {
  PackageResponse,
  PackageTagEnum,
  PackageTypeEnum,
  PriceTypeEnum,
  WorkshopSystemEnum,
  DiscountTypeEnum
} from "src/domain/client";
import { RouterLink } from "@angular/router";
import { NgIf, NgFor } from "@angular/common";
import { WorkshopInfoComponent } from "../workshop-info/workshop-info.component";
import { PackageSummaryComponent } from "../package-summary/package-summary.component";
import { MatDivider } from "@angular/material/divider";
import { BrandCardComponent } from "../brand-card/brand-card.component";
import { PricePipe } from "../../../pipes/sharedpipes/price.pipe";
import { FloorPipe } from "../../../pipes/sharedpipes/floor.pipe";
import { TranslateModule } from "@ngx-translate/core";
import { CarBrandPipe } from "../../../pipes/car-brand.pipe";
import { PackagePricePipe } from "../../../pipes/package-price.pipe";
import { TotalPricePipe } from "../../../pipes/total-price.pipe";

@Component({
  selector: "app-summary",
  templateUrl: "./summary.component.html",
  standalone: true,
  imports: [
    RouterLink,
    NgIf,
    WorkshopInfoComponent,
    NgFor,
    PackageSummaryComponent,
    MatDivider,
    BrandCardComponent,
    PricePipe,
    FloorPipe,
    TranslateModule,
    CarBrandPipe,
    PackagePricePipe,
    TotalPricePipe
  ]
})
export class SummaryComponent implements OnInit {
  public WorkshopSystemEnum: typeof WorkshopSystemEnum = WorkshopSystemEnum;
  public PackageTypeEnum: typeof PackageTypeEnum = PackageTypeEnum;
  public PriceTypeEnum: typeof PriceTypeEnum = PriceTypeEnum;
  public PackageTagEnum: typeof PackageTagEnum = PackageTagEnum;

  @Output() navigation = new EventEmitter<void>();

  public DiscountTypeEnum = DiscountTypeEnum;

  constructor(
    public formService: FormService,
    public pagingService: PagingService,
    public urlService: UrlService,
    public mappingService: MappingService,
    public priceService: PriceService
  ) {}

  ngOnInit(): void {}

  onNavigation(): void {
    this.navigation.emit();
  }

  momentToDateString(date: any): string {
    return date.format("YYYY-MM-DD");
  }

  get troubleshootingForm(): FormGroup<ITroubleshootingForm> {
    return (
      this.formService.form.controls.chooseSpecificationsForm as FormGroup<any>
    ).controls.troubleshootingForm as FormGroup<ITroubleshootingForm>;
  }
  get windscreenForm(): FormGroup<IWindscreenForm> {
    return this.formService.form.controls.chooseSpecificationsForm.get(
      "windscreenForm"
    ) as FormGroup<IWindscreenForm>;
  }

  selectedMainPackages(type: PackageTypeEnum): PackageResponse[] {
    const selected = this.formService.selectedBasePackages;
    return (
      this.formService.basePackagesResponse
        ?.filter((x) => !!x)
        .filter(
          (pkg) =>
            pkg.type === type && !!selected.find((v) => v.packageId === +pkg.id)
        ) ?? []
    );
  }

  selectedBasePackages(pkg: PackageResponse): PackageResponse[] {
    const selected = this.formService.selectedBasePackages.find(
      (j) => j.packageId === +pkg.id
    );
    if (!selected) return [];
    const selectedPackage = this.formService.basePackagesResponse?.filter(
      (p) => +p.id == selected.packageId
    );
    if (!selectedPackage) return [];
    return selectedPackage;
  }

  getSelectedPackagesByType(type: PackageTypeEnum): PackageResponse[] {
    const selected = this.formService.allSelectedPackages;
    if (!this.formService.basePackagesResponse?.length) return [];
    const base =
      this.formService.basePackagesResponse
        ?.filter((x) => !!x)
        .filter(
          (pkg) =>
            pkg.type === type &&
            !!selected.find(
              (v) =>
                v.packageId === +pkg.id ||
                v.packageExternalId === pkg.packageExternalId
            )
        ) ?? [];
    const additional =
      this.formService.additionalPackagesResponse?.filter(
        (pkg) =>
          pkg.type === type &&
          !!selected.find(
            (v) =>
              v.packageId === +pkg.id ||
              v.packageExternalId === pkg.packageExternalId
          )
      ) ?? [];
    return base.concat(additional);
  }

  // THIS SHOULD BE A PIPE
  selectedCalendarPackages(pkg: PackageResponse): PackageResponse[] {
    const selected = this.formService.selectedTimePackages.find(
      (j) => j.packageId === +pkg.id
    );
    if (!selected) return [];
    const selectedPackage = this.formService.basePackagesResponse.filter(
      (p) => +p.id == selected.packageId
    );
    if (!selectedPackage) return [];
    return selectedPackage;
  }

  hasAllSelected(pkg: PackageResponse): boolean {
    if (
      pkg.subPackages.filter((p) => !p.tags.includes(PackageTagEnum.Removable))
        .length >= pkg.subPackages.length
    )
      return true;
    return (
      !!pkg.subPackages.length &&
      this.formService.selectedBasePackages.find((p) => p.packageId === +pkg.id)
        .subPackageIds.length ===
        pkg.subPackages.length - 1
    );
  }

  hasAsteriskPrice(): boolean {
    if (!this.formService.basePackagesResponse) return false;
    const selected = this.formService.selectedBasePackages;
    if (this.formService.additionalPackagesResponse)
      selected.concat(this.formService.selectedAdditionalPackages);
    if (!selected.length) return false;
    const basePackages = this.formService.basePackagesResponse.filter((pkg) =>
      selected.find((v) => v.packageId === +pkg.id)
    );
    const additionalPackages = this.formService.additionalPackagesResponse
      ? this.formService.additionalPackagesResponse.filter((pkg) =>
          selected.find((v) => v.packageId === +pkg.id)
        )
      : [];
    const basePackagesDeterminant = !!basePackages.filter(
      (j) =>
        !!j.subPackages.filter((p) => p.price.type !== PriceTypeEnum.Fixed)
          .length
    ).length;
    const additionalPackagesDeterminant = this.formService
      .additionalPackagesResponse
      ? !!additionalPackages.filter(
          (j) =>
            !!j.subPackages.filter((p) => p.price.type !== PriceTypeEnum.Fixed)
              .length
        ).length
      : false;
    return !!basePackagesDeterminant || !!additionalPackagesDeterminant;
  }
}
