import { Component, OnInit } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogClose,
  MatDialogContent,
  MatDialogActions,
  MatDialogTitle,
} from "@angular/material/dialog";
import { Inject } from "@angular/core";
import { IDialogData } from "src/app/interfaces/interfaces";
import { IconService } from "src/app/services/icon.service";
import { UrlService } from "src/app/services/url.service";
import { CommonModule } from "@angular/common";
import { ButtonComponent } from "../../inputs/button/button.component";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "app-dialog-will-invalidate-form",
  templateUrl: "./dialog-will-invalidate-form.component.html",
  styleUrls: ["./dialog-will-invalidate-form.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogClose,
    MatDialogContent,
    MatDialogActions,
    MatDialogTitle,
    ButtonComponent,
    TranslateModule,
  ],
})
export class DialogWillInvalidateFormComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<DialogWillInvalidateFormComponent>,
    public urlService: UrlService,
    public iconService: IconService,
    @Inject(MAT_DIALOG_DATA) public data: IDialogData
  ) {}

  ngOnInit(): void {
    console.log("-> Init DialogWillInvalidateFormComponent");
  }
  onClose(): void {
    this.dialogRef.close();
  }
}
