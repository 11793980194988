import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PagingService } from "src/app/services/paging.service";
import { DealerResponse, SwaggerException } from "src/domain/client";
import { NgIf, NgTemplateOutlet } from "@angular/common";
import { WorkshopInfoComponent } from "../workshop-info/workshop-info.component";
import { ButtonComponent } from "../../inputs/button/button.component";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "app-request-failed",
  templateUrl: "./request-failed.component.html",
  standalone: true,
  imports: [
    NgIf,
    NgTemplateOutlet,
    WorkshopInfoComponent,
    ButtonComponent,
    TranslateModule
  ]
})
export class RequestFailedComponent implements OnInit {
  @Input() dealer?: DealerResponse;
  @Input() title? = "request-fail.title";
  @Input() subtitle? = "request-fail.subtitle";
  @Input() returnText? = "request-fail.return";
  @Input() returnToPage?: number = 1;
  @Input() returnButtonAbove? = false;
  @Input() message: SwaggerException;

  @Output() returnClicked$ = new EventEmitter();
  constructor(public pagingService: PagingService) {}

  ngOnInit(): void {}

  returnClicked(_event: any) {
    this.returnClicked$.emit();
    this.pagingService.navigateToStep(this.returnToPage);
  }
}
