import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "floor",
  standalone: true
})
export class FloorPipe implements PipeTransform {
  transform(input: number) {
    return Math.floor(input);
  }
}
