import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { MatProgressBar } from "@angular/material/progress-bar";

@Component({
  selector: "app-progress-bar",
  templateUrl: "./progress-bar.component.html",
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [MatProgressBar]
})
export class ProgressBarComponent implements OnInit {
  // Progressbar max is 100, use (100/7)*current step.
  @Input() value: number;
  @Input() thick: boolean;

  constructor() {}

  ngOnInit(): void {}
}
