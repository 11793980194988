import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormService } from "src/app/services/form.service";
import { IconService } from "src/app/services/icon.service";
import { PagingService } from "src/app/services/paging.service";
import { TrackingService } from "src/app/services/tracking.service";
import {
  SwaggerException,
  Client,
  WorkshopSystemEnum,
  ActiveWorkshopSystemResponse,
  VehicleChassiRequest
} from "src/domain/client";
import { PageBaseComponent } from "../../base/page-base/page-base.component";
import { PageContainerComponent } from "../../layout/page-container/page-container.component";
import { NgIf, NgFor } from "@angular/common";
import { LoadingComponent } from "../../layout/loading/loading.component";
import { RequestFailedComponent } from "../../layout/request-failed/request-failed.component";
import { CheckboxCardComponent } from "../../layout/checkbox-card/checkbox-card.component";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "app-workshop-system-selection",
  templateUrl: "./workshop-system-selection.component.html",
  standalone: true,
  imports: [
    PageContainerComponent,
    NgIf,
    LoadingComponent,
    RequestFailedComponent,
    NgFor,
    CheckboxCardComponent,
    TranslateModule
  ]
})
export class WorkshopSystemSelectionComponent
  extends PageBaseComponent
  implements OnInit, OnDestroy
{
  requestFailedMessage: SwaggerException = null;

  constructor(
    public formService: FormService,
    public iconService: IconService,
    private pagingService: PagingService,
    private trackingService: TrackingService
  ) {
    super();
    this.formService.workshopSystemSelectionForm.markAsPristine();
    this.formService.previousFormValue =
      this.formService.workshopSystemSelectionForm.value;
  }
  ngOnDestroy(): void {
    if (!this.formService.workshopSystemSelectionForm.pristine)
      this.trackingService.trackServiceCategoriesChosen(
        this.formService.serviceTypeSelection
      );
    this.cleanUp();
  }

  ngOnInit(): void {
    if (this.formService.activeWorkshopSystemsResponse) {
      this.state = this.ComponentStateEnum.Initialized;
      return;
    }
    new Client()
      .apiV2WorkshopSystemActiveWorkshops(
        new VehicleChassiRequest({
          chassiNumber: this.formService.carInfoResponse.chassiNumber,
          odometer: this.formService.milageValue
        })
      )
      .then(
        (response: ActiveWorkshopSystemResponse[]) => {
          this.formService.activeWorkshopSystemsResponse = response;
          this.state = this.ComponentStateEnum.Initialized;
        },
        (reason: SwaggerException) => {
          if (reason.status === 404 || reason.status === 503)
            this.pagingService.navigateToServiceUnavailable();
          this.requestFailedMessage = reason;
          this.state = this.ComponentStateEnum.ApiCallFailed;
        }
      );
  }
  getActiveWorkshopSystems(): ActiveWorkshopSystemResponse[] {
    const filtered = this.formService.activeWorkshopSystemsResponse.filter(
      (s) => s.workshopSystemId === WorkshopSystemEnum.TechUpdate
    );
    if (filtered.length)
      return filtered.concat(
        this.formService.activeWorkshopSystemsResponse.filter(
          (s) => s.workshopSystemId !== WorkshopSystemEnum.TechUpdate
        )
      );
    return this.formService.activeWorkshopSystemsResponse;
  }
}
