import { Pipe, PipeTransform } from "@angular/core";
@Pipe({
  name: "price",
  standalone: true
})
export class PricePipe implements PipeTransform {
  constructor() {}

  transform(price: number | string): string {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }
}
