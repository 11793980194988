import { Component, Input } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { IViewPair } from "src/app/interfaces/interfaces";
import { NgIf, NgFor } from "@angular/common";
import { MatFormField, MatLabel } from "@angular/material/form-field";
import { MatSelect } from "@angular/material/select";
import { MatOption } from "@angular/material/core";

@Component({
  selector: "app-dropdown",
  templateUrl: "./dropdown.component.html",
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgIf,
    MatFormField,
    MatSelect,
    NgFor,
    MatOption,
    MatLabel
  ]
})
export class DropdownComponent {
  @Input() data: IViewPair[];
  @Input() label?: string;
  @Input() formField?: boolean;
  @Input() parentForm?: FormGroup<any>;
  @Input() formControlKey?: string;

  constructor() {}
}
