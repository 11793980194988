// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.promo-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 340px;
  background-color: var(--white);
}

.promo-image {
  width: 100%;
  border-radius: 4px;
  object-fit: cover;
}

.promo-content {
  text-align: center;
  margin-top: 16px;
}

.promo-title {
  font-size: 1.6em;
  font-weight: bold;
  color: var(--blue500);
  margin: 0;
}

.promo-body {
  font-size: 1.2em;
  color: var(--grey500);
  margin-top: 8px;
  margin-bottom: 8px;
  line-height: 30px;
  font-weight: 600;
  text-align: left;
}

.promo-link {
  display: inline-block;
  margin-top: 8px;
  color: var(--blue300);
  text-decoration: none;
  font-weight: 600;
  text-decoration: underline;
  text-align: left;
  font-size: 1.2em;
}

.promo-link:hover {
  text-decoration: underline;
}

@media screen and (max-width: 992px) {
  .promo-body {
    font-size: 1em;
    font-weight: 500;
  }
  .promo-title {
    font-size: 1.2em;
    font-weight: 600;
  }
  .promo-link {
    font-size: 1em;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/layout/promo-card/promo-card.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,YAAA;EACA,8BAAA;AACF;;AAEA;EACE,WAAA;EACA,kBAAA;EACA,iBAAA;AACF;;AAEA;EACE,kBAAA;EACA,gBAAA;AACF;;AAEA;EACE,gBAAA;EACA,iBAAA;EACA,qBAAA;EACA,SAAA;AACF;;AAEA;EACE,gBAAA;EACA,qBAAA;EACA,eAAA;EACA,kBAAA;EACA,iBAAA;EACA,gBAAA;EACA,gBAAA;AACF;;AAEA;EACE,qBAAA;EACA,eAAA;EACA,qBAAA;EACA,qBAAA;EACA,gBAAA;EACA,0BAAA;EACA,gBAAA;EACA,gBAAA;AACF;;AAEA;EACE,0BAAA;AACF;;AAEA;EACE;IACE,cAAA;IACA,gBAAA;EACF;EAEA;IACE,gBAAA;IACA,gBAAA;EAAF;EAGA;IACE,cAAA;EADF;AACF","sourcesContent":[".promo-card {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  width: 340px;\n  background-color: var(--white);\n}\n\n.promo-image {\n  width: 100%;\n  border-radius: 4px;\n  object-fit: cover;\n}\n\n.promo-content {\n  text-align: center;\n  margin-top: 16px;\n}\n\n.promo-title {\n  font-size: 1.6em;\n  font-weight: bold;\n  color: var(--blue500);\n  margin: 0;\n}\n\n.promo-body {\n  font-size: 1.2em;\n  color: var(--grey500);\n  margin-top: 8px;\n  margin-bottom: 8px;\n  line-height: 30px;\n  font-weight: 600;\n  text-align: left;\n}\n\n.promo-link {\n  display: inline-block;\n  margin-top: 8px;\n  color: var(--blue300);\n  text-decoration: none;\n  font-weight: 600;\n  text-decoration: underline;\n  text-align: left;\n  font-size: 1.2em;\n}\n\n.promo-link:hover {\n  text-decoration: underline;\n}\n\n@media screen and (max-width: 992px) {\n  .promo-body {\n    font-size: 1em;\n    font-weight: 500;\n  }\n\n  .promo-title {\n    font-size: 1.2em;\n    font-weight: 600;\n  }\n\n  .promo-link {\n    font-size: 1em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
