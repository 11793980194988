import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose
} from "@angular/material/dialog";
import {
  IRejectProposalStatus,
  IViewPair
} from "src/app/interfaces/interfaces";
import { IconService } from "src/app/services/icon.service";
import { BusinessProposalService } from "src/app/services/business-proposal.service";
import { FormControl, FormGroup } from "@angular/forms";
import { Subscription } from "rxjs";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { BusinessProposalRejectionReasonEnum } from "src/domain/client";
import { CdkScrollable } from "@angular/cdk/scrolling";
import { DropdownComponent } from "../../inputs/dropdown/dropdown.component";
import { NgIf } from "@angular/common";
import { TextAreaComponent } from "../../inputs/text-area/text-area.component";
import { ButtonComponent } from "../../inputs/button/button.component";

@Component({
  selector: "app-reject-proposal-dialog",
  templateUrl: "./reject-proposal-dialog.component.html",
  standalone: true,
  imports: [
    MatDialogTitle,
    CdkScrollable,
    MatDialogContent,
    DropdownComponent,
    NgIf,
    TextAreaComponent,
    MatDialogActions,
    MatDialogClose,
    ButtonComponent,
    TranslateModule
  ]
})
export class RejectProposalDialogComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  ReasonEnum = BusinessProposalRejectionReasonEnum;
  form = new FormGroup({
    reason: new FormControl<BusinessProposalRejectionReasonEnum | null>(null),
    reasonComment: new FormControl<string>("")
  });
  reasons: IViewPair[] = [
    {
      view: this.translateService.instant(
        "businessProposal.reasons.CustomerDoesNotWantAnAppointment"
      ),
      value:
        BusinessProposalRejectionReasonEnum.CustomerDoesNotWantAnAppointment
    },
    {
      view: this.translateService.instant(
        "businessProposal.reasons.CustomerNoLongerInPossessionOfTheVehicle"
      ),
      value:
        BusinessProposalRejectionReasonEnum.CustomerNoLongerInPossessionOfTheVehicle
    },
    {
      view: this.translateService.instant(
        "businessProposal.reasons.CustomerUsingOtherPartner"
      ),
      value: BusinessProposalRejectionReasonEnum.CustomerUsingOtherPartner
    },
    {
      view: this.translateService.instant(
        "businessProposal.reasons.CustomerNotInterestedRevisit"
      ),
      value: BusinessProposalRejectionReasonEnum.CustomerNotInterestedRevisit
    },
    {
      view: this.translateService.instant("businessProposal.reasons.Other"),
      value: BusinessProposalRejectionReasonEnum.Other
    }
  ];
  rejectProposalStatus: IRejectProposalStatus = {
    error: false,
    loading: false,
    submitted: false
  };

  constructor(
    private dialogRef: MatDialogRef<
      RejectProposalDialogComponent,
      { rejected?: boolean }
    >,
    public iconService: IconService,
    private businessProposalService: BusinessProposalService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.businessProposalService.getRejectProposalStatus().subscribe({
        next: (val) => {
          this.rejectProposalStatus = val;
        }
      })
    );

    this.subscription.add(
      this.form.controls.reason.valueChanges.subscribe({
        next: (val) => {
          this.businessProposalService.rejectedReason = val;
        }
      })
    );

    this.subscription.add(
      this.form.controls.reasonComment.valueChanges.subscribe({
        next: (val) => {
          this.businessProposalService.rejectedReasonComment = val;
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onClose(): void {
    this.dialogRef.close();
  }

  async submitRejectProposal(): Promise<void> {
    try {
      const proposalId =
        this.businessProposalService.businessProposal$.value.businessProposalId;
      const reason = this.businessProposalService.rejectedReason || undefined;
      const comment =
        this.businessProposalService.rejectedReasonComment || undefined;

      await this.businessProposalService.rejectProposal(
        proposalId,
        reason,
        comment
      );

      this.dialogRef.close({ rejected: true });
    } catch (err) {}
  }
}
