import { Location } from "@angular/common";
import { ElementRef, Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { FeatureAppService } from "./feature-app.service";
import { UrlService } from "./url.service";
import { PageFormIdEnum } from "../classes/enums";

@Injectable({
  providedIn: "root"
})
export class PagingService {
  private routerContainerRef: ElementRef = null;
  private lastSentPageSize = 0;

  constructor(
    @Inject(DOCUMENT) private _document: HTMLDocument,
    private location: Location,
    private router: Router,
    private featureAppService: FeatureAppService,
    private urlService: UrlService
  ) {}

  get formStepLocation(): PageFormIdEnum {
    const step = parseInt(this.location.path().split("?")[0].slice(-1), 10);
    return step ? step : 1;
  }

  get isForm(): boolean {
    const step = parseInt(this.location.path().split("?")[0].slice(-1), 10);
    return !!step;
  }

  get isProposal(): boolean {
    return this.location.path().split("/").includes("service-link");
  }

  get nextStepUrl(): string {
    const step = this.formStepLocation;
    if (step === PageFormIdEnum.summary) {
      return this.urlService.getBasePath() + "/complete";
    } else {
      return this.urlService.getBasePath() + "/form/" + (step + 1) + "";
    }
  }

  get previousStepUrl(): string {
    const step = this.formStepLocation;
    if (step === PageFormIdEnum.aboutVehicle) {
      return this.urlService.getBasePath() + "/form/1";
    } else {
      return this.urlService.getBasePath() + "/form/" + (step - 1) + "";
    }
  }

  get logoEnabled(): boolean {
    if (this.featureAppService.shouldHideLogo()) return false;
    return ["vw", "audi", "skoda", "seat", "cupra", "porsche"].includes(
      this.urlService.brand
    );
  }

  navigateToStep(step: number): void {
    this.router.navigate(
      [this.urlService.getBasePath() + "/form/" + step.toString()],
      {
        queryParamsHandling: "merge"
      }
    );
  }

  navigateToServiceUnavailable(): void {
    this.router.navigate([this.urlService.getBasePath() + "/unavailable"]);
  }

  navigateToProposalRejected(): void {
    this.router.navigate([
      this.urlService.getBasePath() + "/rejected-proposal"
    ]);
  }

  rebrand(brand: string): void {
    this.router.navigate(
      ["/" + this.featureAppService.getFeatureAppSubPath() + brand + "/1"],
      { queryParamsHandling: "merge" }
    );
  }

  navigateToExternalUrl(url: string): void {
    window.location.href = url;
  }

  setFavicon(): void {
    if (this.featureAppService.isFeatureApp) return;
    const favicon = this._document.getElementById("favicon");
    if (!favicon) return;
    favicon.setAttribute(
      "href",
      "assets/favicons/" + this.urlService.brand + ".ico"
    );
  }

  setRouterContainerRef(ref: ElementRef): void {
    if (!ref) {
      console.log("failed to set router container ref");
      return;
    }
    if (!this.routerContainerRef) console.log("set router container ref");
    this.routerContainerRef = ref;
  }

  notifyIframeParent(): void {
    if (!this.routerContainerRef) {
      console.log("no parent found, could not signal iframe");
      return;
    }
    if (
      this.routerContainerRef.nativeElement.scrollHeight == 0 ||
      this.lastSentPageSize ===
        this.routerContainerRef.nativeElement.scrollHeight
    )
      return;
    window.parent.postMessage(
      {
        message: "resize",
        top: this.routerContainerRef.nativeElement.scrollHeight + 240
      },
      "*"
    );
    console.log(
      "iframe height change",
      this.routerContainerRef.nativeElement.scrollHeight
    );
    this.lastSentPageSize = this.routerContainerRef.nativeElement.scrollHeight;
  }
}
