import { Pipe, PipeTransform } from "@angular/core";
import { BrandCodeEnum } from "src/domain/client";

@Pipe({
  name: "baseServiceLink",
  standalone: true
})
export class BaseServiceLinkPipe implements PipeTransform {
  transform(brand: string): string {
    switch (brand) {
      case BrandCodeEnum.A:
        return "https://www.audi.se/se/web/sv/customer-area/service-tillbehor/originalservice.html#Basservice";
      case BrandCodeEnum.C:
        return "https://www.skoda.se/aga-bil/original-basservice";
      case BrandCodeEnum.S:
        return "https://www.seat.se/aga/service/seat-originalservice/basservice";
      case BrandCodeEnum.N:
        return "https://www.volkswagen-transportbilar.se/sv/aga-bil/service-och-underhall/basservice.html";
      case BrandCodeEnum.V:
        return "https://www.volkswagen.se/sv/aga-en-vw/service/basservice.html";
    }
    return "";
  }
}
