import { Injectable } from "@angular/core";
import { environment } from "./../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class FeatureService {
  constructor() {}

  public isFeatureEnabled(feature: string): boolean {
    return environment.features && environment.features.includes(feature);
  }
}
