import { Component, OnInit } from "@angular/core";
import { FormService } from "src/app/services/form.service";
import { FirstToUpperPipe } from "../../../pipes/sharedpipes/first-to-upper.pipe";
import { TranslateModule } from "@ngx-translate/core";
import { RoadAssistantLinkPipe } from "../../../pipes/road-assistant-link.pipe";
import { BaseServiceLinkPipe } from "../../../pipes/base-service-link.pipe";
import { CarBrandPipe } from "../../../pipes/car-brand.pipe";

@Component({
  selector: "app-base-service-package-info",
  templateUrl: "./base-service-package-info.component.html",
  standalone: true,
  imports: [
    FirstToUpperPipe,
    TranslateModule,
    RoadAssistantLinkPipe,
    BaseServiceLinkPipe,
    CarBrandPipe
  ]
})
export class BaseServicePackageInfoComponent implements OnInit {
  constructor(public formService: FormService) {}

  ngOnInit(): void {}
}
