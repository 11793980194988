export enum FormTypeEnum {
  ServiceForm = "serviceForm",
  WheelForm = "wheelForm",
  WindscreenForm = "windscreenForm",
  TroubleshootingForm = "troubleshootingForm",
  TechUpdateForm = "techUpdateForm"
}

export enum BrandEnum {
  vw = "vw",
  skoda = "skoda",
  seat = "seat",
  audi = "audi",
  cupra = "cupra",
  porsche = "porsche"
}

export enum DialogTypeEnum {
  summary = "summary",
  mobility = "mobility",
  originalService = "originalService",
  cookie = "cookie",
  userInfoConsent = "userinfoconsent",
  usageConditions = "usageconditions",
  roadAssistant = "roadassistant",
  externalTyre = "externalTyre"
}

export enum PageFormNameEnum {
  aboutVehicle = "aboutVehicleForm",
  workshopSystemSelection = "workshopSystemSelectionForm",
  findDealer = "findDealerForm",
  chooseSpecifications = "chooseSpecificationsForm",
  extraServices = "extraServicesForm",
  timeSelection = "timeSelectionForm",
  contactInformation = "contactInformationForm"
}

export enum PageFormIdEnum {
  aboutVehicle = 1,
  workshopSystemSelection = 2,
  findDealer = 3,
  chooseSpecifications = 4,
  extraServices = 5,
  timeSelection = 6,
  contactInformation = 7,
  summary = 8
}

export enum InfoCardTypesEnum {
  standard = "standard",
  warning = "warning"
}

export const businessProposalInitStep = 4;
