import { Component, Input } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { ImageResolverPipe } from "../../../pipes/sharedpipes/image-resolver.pipe";

@Component({
  selector: "app-promo-card",
  templateUrl: "./promo-card.component.html",
  styleUrls: ["./promo-card.component.scss"],
  standalone: true,
  imports: [ImageResolverPipe]
})
export class PromoCardComponent {
  @Input() imageUrl: string;
  @Input() title: string;
  @Input() linkText: string;
  @Input() linkUrl: string;

  private _body: SafeHtml;

  @Input()
  set body(value: string) {
    this._body = this.sanitizer.bypassSecurityTrustHtml(value);
  }

  get body(): SafeHtml {
    return this._body;
  }

  constructor(private sanitizer: DomSanitizer) {}
}
