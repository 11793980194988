import { Component } from "@angular/core";
import { BusinessProposalService } from "src/app/services/business-proposal.service";
import { BusinessProposalRejectionReasonEnum } from "src/domain/client";
import { NgIf } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "app-rejected-proposal-success",
  templateUrl: "./rejected-proposal-success.component.html",
  standalone: true,
  imports: [NgIf, TranslateModule]
})
export class RejectedProposalComponent {
  RejectedReasonEnum = BusinessProposalRejectionReasonEnum;
  rejectedReason: BusinessProposalRejectionReasonEnum | undefined;
  rejectedReasonComment: string | undefined;

  constructor(private businessProposalService: BusinessProposalService) {
    this.rejectedReason = this.businessProposalService.rejectedReason;
    this.rejectedReasonComment =
      this.businessProposalService.rejectedReasonComment;
  }
}
