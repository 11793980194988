import { Component, Input, OnInit } from "@angular/core";
import { NgIf } from "@angular/common";
import { MatProgressSpinner } from "@angular/material/progress-spinner";

@Component({
  selector: "app-loading",
  templateUrl: "./loading.component.html",
  standalone: true,
  imports: [NgIf, MatProgressSpinner]
})
export class LoadingComponent implements OnInit {
  @Input() text: string;
  // size in pixels. Will apply to width and height
  @Input() size?: number;

  constructor() {}

  ngOnInit(): void {}
}
