import { Component } from "@angular/core";
import { PageContainerComponent } from "../../layout/page-container/page-container.component";
import { SummaryComponent } from "../../layout/summary/summary.component";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "app-review",
  templateUrl: "./review.component.html",
  standalone: true,
  imports: [PageContainerComponent, SummaryComponent, TranslateModule]
})
export class ReviewComponent {
  constructor() {}
}
