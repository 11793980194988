// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title {
  font-size: 44px;
  color: #234769;
  font-weight: 600;
}

.card-container {
  gap: 50px;
  margin: 3rem;
}

@media screen and (max-width: 992px) {
  .title {
    font-size: 24px;
  }
  .card-container {
    margin: 2rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/views/complete/components/upsales-container/upsales-container.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,cAAA;EACA,gBAAA;AACF;;AACA;EACE,SAAA;EACA,YAAA;AAEF;;AACA;EACE;IACE,eAAA;EAEF;EACA;IACE,YAAA;EACF;AACF","sourcesContent":[".title {\n  font-size: 44px;\n  color: #234769;\n  font-weight: 600;\n}\n.card-container {\n  gap: 50px;\n  margin: 3rem;\n}\n\n@media screen and (max-width: 992px) {\n  .title {\n    font-size: 24px;\n  }\n\n  .card-container {\n    margin: 2rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
