import { Injectable } from "@angular/core";
import {
  BusinessProposalRejectionReasonEnum,
  BusinessProposalResponse,
  BusinessProposalStatusEnum,
  Client,
  PackageResponse,
  PackageTypeEnum,
  RejectBusinessProposalRequest
} from "src/domain/client";
import { FormService } from "./form.service";
import { Router } from "@angular/router";
import { ComponentStateEnum } from "../components/base/page-base/page-base.component";
import { BehaviorSubject, Observable } from "rxjs";
import { CarBrandPipe } from "../pipes/car-brand.pipe";
import { TrackingService } from "./tracking.service";
import { businessProposalInitStep } from "../classes/enums";
import {
  IRejectProposalStatus,
  SelectedProposalService
} from "../interfaces/interfaces";
import { IActiveWorkshopSystemResponseBusinessProposal } from "../classes/active-workshop-system-response-business-proposal";

@Injectable({
  providedIn: "root"
})
export class BusinessProposalService {
  private client: Client;
  businessProposal$ = new BehaviorSubject<BusinessProposalResponse | null>(
    null
  );
  private status$ = new BehaviorSubject<ComponentStateEnum>(
    ComponentStateEnum.Created
  );
  private rejectProposalStatus$ = new BehaviorSubject<IRejectProposalStatus>({
    error: false,
    submitted: false,
    loading: false
  });
  private _rejectedReason: BusinessProposalRejectionReasonEnum | null = null;
  private _rejectedReasonComment: string | null = null;
  private statusReason = "";
  isBusinessProposalActive = false;
  proposalServices: SelectedProposalService | null = null;

  constructor(
    private formService: FormService,
    private router: Router,
    private carBrandPipe: CarBrandPipe,
    private trackingService: TrackingService
  ) {
    this.client = new Client();
  }

  getStatusReasonUntranslated(): string {
    return this.statusReason;
  }

  getStatus(): Observable<ComponentStateEnum> {
    return this.status$;
  }

  getRejectProposalStatus(): Observable<IRejectProposalStatus> {
    return this.rejectProposalStatus$;
  }

  get rejectedReason() {
    return this._rejectedReason;
  }

  set rejectedReason(reason: BusinessProposalRejectionReasonEnum) {
    this._rejectedReason = reason;
  }

  get rejectedReasonComment() {
    return this._rejectedReasonComment;
  }

  set rejectedReasonComment(comment: string) {
    this._rejectedReasonComment = comment;
  }

  async initBusinessProposal(
    proposalId: string,
    shouldRebrand: boolean
  ): Promise<void> {
    try {
      this.status$.next(ComponentStateEnum.Loading);
      this.isBusinessProposalActive = true;

      const businessProposal = await this.getBusinessProposal(proposalId);

      // the id of PPS products is an empty string so it causes problems on forms and id comparisons.
      businessProposal.packages = businessProposal.packages.map(
        (pkg) =>
          new PackageResponse({
            ...pkg,
            id: pkg.id || pkg.packageExternalId
          })
      );

      if (
        businessProposal.businessProposalStatus ===
        BusinessProposalStatusEnum.Expired
      ) {
        this.setAppState(businessProposal);
        this.statusReason = "proposal.expired";
        this.initFailed();
        return;
      }
      await this.markProposalAsViweded(proposalId);
      this.businessProposal$.next(businessProposal);

      this.trackingService.trackSelectCar(
        Boolean(
          !businessProposal.vehicle.chassiNumber &&
            businessProposal.vehicle.registrationNumber
        ),
        businessProposal.vehicle.modelVariantName,
        businessProposal.vehicle.brandName,
        businessProposal.vehicle.modelYear.toString(),
        "", // We don't have this right now
        businessProposal.vehicle.hasServiceAgreement,
        businessProposal.vehicle.isCompanyVehicle,
        businessProposal.vehicle.originalVehicleSegment.toString(),
        businessProposal.vehicle.fuelType
      );

      const brand = shouldRebrand
        ? this.carBrandPipe.transform(businessProposal.vehicle)
        : "default";

      this.setAppState(businessProposal);
      this.status$.next(ComponentStateEnum.Initialized);
      this.router.navigate([`/${brand}/form/${businessProposalInitStep}`], {
        queryParamsHandling: "merge"
      });
    } catch (err) {
      this.initFailed();
    }
  }

  private initFailed(): void {
    this.status$.next(ComponentStateEnum.InitializationFailed);
    this.isBusinessProposalActive = false;
  }

  private getBusinessProposal(
    proposalId: string
  ): Promise<BusinessProposalResponse> {
    return this.client.apiV2BusinessProposalGetBusinessProposal(proposalId);
  }

  private setAppState(proposal: BusinessProposalResponse): void {
    this.formService.aboutVehicleForm.patchValue({
      carText:
        proposal.vehicle.registrationNumber || proposal.vehicle.chassiNumber,
      milage: Math.round(proposal.mileage / 10)
    });
    this.formService.carInfoResponse = proposal.vehicle;

    if (
      proposal.businessProposalStatus !== BusinessProposalStatusEnum.Expired
    ) {
      this.formService.dealersResponse = [proposal.dealer];
      this.formService.findDealerForm.patchValue({
        workshop: proposal.dealer.number
      });

      this.proposalServices = proposal.packages.reduce<SelectedProposalService>(
        (acc, item) => {
          if (
            item.type === PackageTypeEnum.OriginalService ||
            item.type === PackageTypeEnum.BaseService
          ) {
            acc.Service = true;
          }

          if (item.type === PackageTypeEnum.Troubleshooting) {
            acc.Troubleshooting = true;
          }

          if (item.type === PackageTypeEnum.TechUpdate) {
            acc.TechUpdate = true;
          }

          if (item.type === PackageTypeEnum.Wheel) {
            acc.Wheel = true;
          }

          if (item.type === PackageTypeEnum.Windshield) {
            acc.Windscreen = true;
          }

          return acc;
        },
        {
          Service: false,
          TechUpdate: false,
          Troubleshooting: false,
          Wheel: false,
          Windscreen: false,
          Other: false
        }
      );
      const mappedServices = proposal.workshopSystems.map(
        (item) =>
          new IActiveWorkshopSystemResponseBusinessProposal({
            ...item,
            selectedFromBusinessProposal:
              this.proposalServices[item.workshopSystemId]
          })
      );
      this.formService.activeWorkshopSystemsResponse = mappedServices;
      this.formService.workshopSystemSelectionForm.patchValue({
        Service: this.proposalServices.Service,
        Other: this.proposalServices.Other,
        TechUpdate: this.proposalServices.TechUpdate,
        Troubleshooting: this.proposalServices.Troubleshooting,
        Wheel: this.proposalServices.Wheel,
        Windscreen: this.proposalServices.Windscreen
      });
    }

    this.formService.contactInformationForm.patchValue({
      firstName: proposal.customer.firstName,
      lastName: proposal.customer.lastName,
      phone: proposal.customer.phone,
      email: proposal.customer.email,
      smsChecked: proposal.customer.sendSms
    });
  }

  private markProposalAsViweded(proposalId: string): Promise<void> {
    return this.client.apiV2BusinessProposalMarkProposalAsRead(proposalId);
  }

  async rejectProposal(
    proposalId: string,
    reason: BusinessProposalRejectionReasonEnum,
    comment: string | undefined = undefined
  ) {
    try {
      if (this.rejectProposalStatus$.value.loading) return;

      this.rejectProposalStatus$.next({
        loading: true,
        submitted: false,
        error: false
      });

      await this.client.apiV2BusinessProposalRejectBusinessProposal(
        new RejectBusinessProposalRequest({
          proposalId,
          rejectionReason: reason,
          comment
        })
      );

      this.rejectProposalStatus$.next({
        loading: false,
        submitted: true,
        error: false
      });
    } catch (err) {
      this.rejectProposalStatus$.next({
        loading: false,
        submitted: false,
        error: false
      });
    }
  }
}
