import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { FormService } from "src/app/services/form.service";
import { PagingService } from "src/app/services/paging.service";
import { TrackingService } from "src/app/services/tracking.service";
import { BrandEnum, DialogTypeEnum } from "src/app/classes/enums";
import { DialogComponent } from "../dialog/dialog.component";
import { IconService } from "src/app/services/icon.service";
import { TotalPricePipe } from "src/app/pipes/total-price.pipe";
import { UrlService } from "src/app/services/url.service";
import { NgIf } from "@angular/common";
import { IconComponent } from "../icon/icon.component";
import { ImageResolverPipe } from "../../../pipes/sharedpipes/image-resolver.pipe";
import { PricePipe } from "../../../pipes/sharedpipes/price.pipe";
import { FloorPipe } from "../../../pipes/sharedpipes/floor.pipe";
import { TranslateModule } from "@ngx-translate/core";
import { CarBrandPipe } from "../../../pipes/car-brand.pipe";
import { TotalPricePipe as TotalPricePipe_1 } from "../../../pipes/total-price.pipe";

@Component({
  selector: "app-totalprice",
  templateUrl: "./totalprice.component.html",
  standalone: true,
  imports: [
    NgIf,
    IconComponent,
    ImageResolverPipe,
    PricePipe,
    FloorPipe,
    TranslateModule,
    CarBrandPipe,
    TotalPricePipe_1
  ]
})
export class TotalpriceComponent implements OnInit {
  public collapsed = true;
  public BrandEnum = BrandEnum;

  constructor(
    public formService: FormService,
    public pagingService: PagingService,
    public dialog: MatDialog,
    private trackingService: TrackingService,
    public iconService: IconService,
    private totalPricePipe: TotalPricePipe,
    public urlService: UrlService
  ) {}

  ngOnInit(): void {}
  onOpen(): void {
    this.collapsed = !this.collapsed;
    this.trackingService.trackBookingDetailsOpen(
      Math.floor(
        this.totalPricePipe.transform(this.formService.allSelectedPackages)
      ),
      this.formService.allSelectedProducts
    );
    document.getElementById("vbo-header").scrollIntoView(true);
    const ref = this.dialog.open(DialogComponent, {
      data: { componentName: DialogTypeEnum.summary, title: "Min bokning" },
      minWidth: "60%",
      maxWidth: "95vw",
      height: "80%"
    });

    const obs = ref.afterClosed();
    obs.subscribe({ next: (_) => (this.collapsed = !this.collapsed) });
  }
}
