import { Component } from "@angular/core";
import { UrlService } from "src/app/services/url.service";
import { IconComponent } from "../icon/icon.component";
import { RouterLink } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "app-footer-menu",
  templateUrl: "./footer-menu.component.html",
  standalone: true,
  imports: [IconComponent, RouterLink, TranslateModule]
})
export class FooterMenuComponent {
  constructor(public urlService: UrlService) {}
}
