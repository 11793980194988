import { Injectable } from "@angular/core";
import { UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { BusinessProposalService } from "../services/business-proposal.service";

@Injectable({
  providedIn: "root"
})
export class BusinessProposalGuard {
  constructor(private businessProposalService: BusinessProposalService) {}
  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const proposalExists =
      this.businessProposalService.isBusinessProposalActive;

    if (!proposalExists) {
      return false;
    }

    return true;
  }
}
