import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "timeslotDisplay",
  standalone: true
})
export class TimeslotDisplayPipe implements PipeTransform {
  transform(value: string): string {
    const values = value.split(":");
    return values[0] + ":" + values[1];
  }
}
