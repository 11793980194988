import { Injectable } from "@angular/core";
import { Client, FileParameter } from "src/domain/client";
import { FormService } from "./form.service";

@Injectable({
  providedIn: "root"
})
export class FileService {
  public fileIds: number[] = [];
  constructor(private formService: FormService) {}

  async uploadFile(file: FileParameter): Promise<number> {
    await new Client()
      .apiV2ImageUpload(this.formService.carInfoResponse.chassiNumber, file)
      .then((_: any) => this.fileIds.push(+_));
    return this.fileIds[this.fileIds.length - 1];
  }

  clearFile(fileId: number): void {
    if (!fileId) return;
    const index = this.fileIds.indexOf(fileId);
    if (index > -1) this.fileIds.splice(index, 1);
  }
}
