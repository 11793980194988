import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "replace",
  standalone: true
})
export class ReplacePipe implements PipeTransform {
  transform(value: string, target: string, replacer: string): string {
    return value.replace(target, replacer);
  }
}
