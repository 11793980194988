import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  Renderer2
} from "@angular/core";
import { AnimationEvent } from "@angular/animations";
import { Subscription } from "rxjs";
import { expandFooterPopupAnimation } from "src/app/animations/expandFooterPopup";
import { FeatureAppService } from "src/app/services/feature-app.service";
import {
  FooterPopupService,
  ViewConfig
} from "src/app/services/footer-popup.service";
import { IconService } from "src/app/services/icon.service";
import { NgIf, NgSwitch, NgSwitchCase } from "@angular/common";
import { IconComponent } from "../icon/icon.component";
import { SummaryComponent } from "../summary/summary.component";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "app-footer-popup",
  templateUrl: "./footer-popup.component.html",
  animations: [expandFooterPopupAnimation],
  standalone: true,
  imports: [
    NgIf,
    NgSwitch,
    NgSwitchCase,
    IconComponent,
    SummaryComponent,
    TranslateModule
  ]
})
export class FooterPopupComponent implements OnInit, OnDestroy {
  renderView: Readonly<ViewConfig> | null = null;
  @ViewChild("popupContainer") popupContainer: ElementRef<HTMLDivElement>;

  closePopup() {
    this.footerPopupService.close();
  }

  constructor(
    private element: ElementRef<HTMLElement>,
    private featureAppService: FeatureAppService,
    private footerPopupService: FooterPopupService,
    private renderer: Renderer2,
    public iconService: IconService
  ) {}

  private subscription: Subscription | null = null;
  protected isOpen = false;

  ngOnInit(): void {
    if (this.subscription) this.subscription.unsubscribe();
    this.subscription = this.footerPopupService.view$.subscribe((view) => {
      this.isOpen = view != null;
      if (view != null) {
        this.renderView = view;
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.subscription = null;
    this.endClose();
  }

  private scrollCache: number | null = null;

  private browserClientRects() {
    const el = this.renderer.createElement("div") as HTMLDivElement;
    this.renderer.setStyle(el, "width", "100vw");
    this.renderer.setStyle(el, "height", "100vh");
    this.renderer.setStyle(el, "position", "fixed");
    this.renderer.setStyle(el, "top", "0");
    this.renderer.setStyle(el, "left", "0");
    this.renderer.setStyle(el, "pointer-events", "none");
    this.renderer.appendChild(this.element.nativeElement, el);
    const outerRect = el.getBoundingClientRect();
    el.remove();
    let rootElement = this.element.nativeElement;
    while (rootElement.parentElement != null) {
      rootElement = rootElement.parentElement;
    }
    const innerRect = rootElement.getBoundingClientRect();
    return {
      outerRect,
      innerRect
    };
  }

  private startOpen() {
    this.renderer.addClass(this.element.nativeElement, "footer-popup__open");
    if (this.featureAppService.isFeatureApp) {
      const el = document.getElementById("vbo-header");
      if (el) el.scrollIntoView(true);
    } else {
      this.scrollCache = this.scrollCache ?? document.body.scrollTop;
      const {
        outerRect: { width: viewportWidth },
        innerRect: { width: bodyWidth }
      } = this.browserClientRects();
      const scrollWidth = viewportWidth - bodyWidth;
      this.renderer.setProperty(
        document.body,
        "style",
        `--hide-scroll__scroll-pos: ${this.scrollCache}px;--hide-scroll__scroll-width: ${scrollWidth}px`
      );
      this.renderer.addClass(document.body, "hide-scroll");
      this.renderer.addClass(document.body, "hide-scroll__footer-popup");
      if (document.body.clientHeight < document.body.scrollHeight) {
        this.renderer.addClass(
          this.element.nativeElement,
          "footer-popup__scroll"
        );
      }
    }
  }

  private endClose() {
    this.renderer.removeClass(this.element.nativeElement, "footer-popup__open");
    if (!this.featureAppService.isFeatureApp && this.scrollCache != null) {
      this.renderer.removeClass(document.body, "hide-scroll");
      this.renderer.setProperty(document.body, "style", null);
      document.body.scrollTo({
        top: this.scrollCache,
        behavior: "instant"
      });
      this.scrollCache = null;
    }
    this.renderer.removeClass(
      this.element.nativeElement,
      "footer-popup__scroll"
    );
    this.renderView = null;
  }

  openCloseEvent(event: AnimationEvent) {
    if (event.phaseName === "start" && event.toState === "open") {
      this.startOpen();
    } else if (event.phaseName === "done" && event.toState === "closed") {
      this.endClose();
    }
  }
}
