import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ITroubleshootingForm } from "src/app/interfaces/interfaces";
import { ExtenderService } from "src/app/services/extender.service";
import { FileService } from "src/app/services/file.service";
import { FormService } from "src/app/services/form.service";
import { IconService } from "src/app/services/icon.service";
import { FileParameter } from "src/domain/client";
import { TextAreaComponent } from "../../inputs/text-area/text-area.component";
import { NgIf, NgFor } from "@angular/common";
import { IconComponent } from "../icon/icon.component";
import { ButtonComponent } from "../../inputs/button/button.component";
import { CheckboxCardComponent } from "../checkbox-card/checkbox-card.component";
import { ViewMoreComponent } from "../view-more/view-more.component";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "app-fault-report",
  templateUrl: "./fault-report.component.html",
  standalone: true,
  imports: [
    TextAreaComponent,
    NgIf,
    IconComponent,
    ButtonComponent,
    NgFor,
    CheckboxCardComponent,
    ViewMoreComponent,
    TranslateModule
  ]
})
export class FaultReportComponent implements OnInit {
  private fileId: number = null;
  @ViewChild("troubleshooting_file_upload") fileUploadElementRef: ElementRef;

  constructor(
    public formService: FormService,
    public extenderService: ExtenderService,
    public iconService: IconService,
    public fileService: FileService
  ) {}

  ngOnInit(): void {}

  get troubleshootingForm(): FormGroup<ITroubleshootingForm> {
    return this.formService.chooseSpecificationsForm.controls
      .troubleshootingForm;
  }

  getTroubleshootingIconsForm(): FormGroup<any> {
    return this.troubleshootingForm.controls.icons;
  }

  onTroubleshootingFileChanged(event: any): void {
    if (event.target.files?.length) {
      const [newFile] = event.target.files;
      if (newFile.size > 30000000) {
        this.troubleshootingForm.get("file").setErrors({ large: true });
        return;
      }
      // Reads the file into a FileParameter and also converts image to blob
      const reader = new FileReader();
      reader.onload = () => {
        const fileParameter: FileParameter = {
          data: new Blob([reader.result as ArrayBuffer], {
            type: newFile.type
          }),
          fileName: newFile.name
        };
        this.fileService
          .uploadFile(fileParameter)
          .then((_) => (this.fileId = _));
      };
      this.troubleshootingForm.patchValue({
        file: newFile
      });
      reader.readAsArrayBuffer(newFile);
    }
  }
  onTroubleshootingFileClear(): void {
    this.troubleshootingForm.patchValue({
      file: null
    });
    this.fileService.clearFile(this.fileId);
  }

  handleFileUpload(): void {
    this.fileUploadElementRef.nativeElement.click();
  }
}
