import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import {
  AbstractControl,
  FormGroup,
  ReactiveFormsModule
} from "@angular/forms";
import { NgxMaskDirective } from "ngx-mask";
import { NgIf } from "@angular/common";
import { MatIconButton } from "@angular/material/button";
import { MatSuffix } from "@angular/material/form-field";
import { MatIcon } from "@angular/material/icon";

@Component({
  selector: "app-text-box",
  templateUrl: "./text-box.component.html",
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgxMaskDirective,
    NgIf,
    MatIconButton,
    MatSuffix,
    MatIcon
  ]
})
export class TextBoxComponent implements OnInit {
  @Input() type: "text" | "email" | "password" | "tel" | "number";
  @Input() id: string;
  @Input() parentForm: FormGroup<any>;
  @Input() formControlKey: string;
  @Input() placeholder?: string;
  @Input() maxLen?: number;
  @Input() clearable?: boolean;
  @Input() showBorder?: boolean;
  @Input() invalidText?: string;
  @Input() mask?: string;
  @Output() cleared = new EventEmitter<void>();

  @Output() confirm$ = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  clear(): void {
    this.control.setValue("");
    if (this.cleared) {
      this.cleared.emit();
    }
  }

  confirm(): void {
    this.confirm$.emit();
  }

  get control(): AbstractControl {
    return this.parentForm.get(this.formControlKey);
  }

  validate(): void {
    if (!this.control.valid) {
      this.control.setErrors({ invalid: true });
    }
  }
}
