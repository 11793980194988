import {
  ActiveWorkshopSystemResponse,
  IActiveWorkshopSystemResponse,
} from "src/domain/client";

export class IActiveWorkshopSystemResponseBusinessProposal extends ActiveWorkshopSystemResponse {
  selectedFromBusinessProposal?: boolean | undefined;

  constructor(
    data?: IActiveWorkshopSystemResponse & {
      selectedFromBusinessProposal?: boolean;
    }
  ) {
    super(data);
    this.selectedFromBusinessProposal = data.selectedFromBusinessProposal;
  }
}
