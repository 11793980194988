import { Injectable, OnDestroy } from "@angular/core";
import { Observable, Subscriber, Subscription } from "rxjs";
import { ConsentService } from "./consent.service";
import { FeatureAppService } from "./feature-app.service";
import { PagingService } from "./paging.service";
import { PageFormIdEnum } from "../classes/enums";
import { NavigationEnd, Router, Event } from "@angular/router";
import { filter } from "rxjs/operators";
import { TrackingService } from "./tracking.service";
import { FormService } from "./form.service";
import { TotalPricePipe } from "../pipes/total-price.pipe";

type Views = {
  summary?: never;
  custom: {
    html: string;
    title: string;
  };
};

export type ViewConfig =
  | {
      [key in keyof Views]: Pick<Views, key> extends Partial<Record<key, never>>
        ? never
        : [view: key, data: Views[key]];
    }[keyof Views]
  | {
      [key in keyof Views]: {} extends Pick<Views, key> ? [view: key] : never;
    }[keyof Views];

@Injectable({
  providedIn: "root"
})
export class FooterPopupService implements OnDestroy {
  public view$: Observable<Readonly<ViewConfig> | null>;
  private _subscribers = new Set<Subscriber<Readonly<ViewConfig> | null>>();

  readonly currentView: Readonly<ViewConfig> | null = null;

  private _subscription: Subscription | null = null;

  constructor(
    private consentService: ConsentService,
    private featureAppService: FeatureAppService,
    private pagingService: PagingService,
    private router: Router,
    private formService: FormService,
    private trackingService: TrackingService,
    private totalPricePipe: TotalPricePipe
  ) {
    this._subscription = this.router.events
      .pipe(
        filter((e: Event): e is NavigationEnd => e instanceof NavigationEnd)
      )
      .subscribe(() => {
        this.close();
      });
    this.view$ = new Observable((subscriber) => {
      const subscribers = this._subscribers;
      try {
        subscribers.add(subscriber);
      } catch (e) {
        // Log this as an uncaught error, but don't stop chain of subscribers;
        console.error("Uncaught", e);
      }
      subscriber.next(this.currentView);
      return {
        unsubscribe() {
          subscribers.delete(subscriber);
        }
      };
    });
    Object.defineProperty(this, "currentView", {
      value: this.currentView,
      enumerable: true,
      writable: false,
      configurable: true
    });
  }

  ngOnDestroy(): void {
    if (this._subscription != null) this._subscription.unsubscribe();
    this._subscription = null;
    this.view$ = new Observable((s) => {
      s.complete();
    });
    const toComplete = [...this._subscribers];
    this._subscribers.clear();
    toComplete.forEach((s) => {
      try {
        s.complete();
      } catch (e) {
        // Log this as an uncaught error, but don't stop chain of subscribers;
        console.error("Uncaught", e);
      }
    });
  }

  open(...args: ViewConfig): void {
    if (
      !this.consentService.hasRequiredConsent &&
      !this.featureAppService.isFeatureApp
    ) {
      return;
    }
    if (
      this.pagingService.formStepLocation >= PageFormIdEnum.contactInformation
    ) {
      return;
    }
    const newView = args.slice(0, 2) as ViewConfig;
    Object.freeze(newView);
    if (
      this.currentView != null &&
      this.currentView.length === this.currentView.length &&
      (this.currentView as readonly unknown[]).every((v, i) => newView[i] === v)
    ) {
      return;
    }
    if (newView[0] === "summary") {
      this.trackingService.trackBookingDetailsOpen(
        Math.floor(
          this.totalPricePipe.transform(this.formService.allSelectedPackages)
        ),
        this.formService.allSelectedProducts
      );
    }
    Object.defineProperty(this, "currentView", {
      value: newView
    });
    this._subscribers.forEach((s) => {
      try {
        s.next([...newView]);
      } catch (e) {
        // Log this as an uncaught error, but don't stop chain of subscribers;
        console.error("Uncaught", e);
      }
    });
  }

  close(): void {
    if (this.currentView == null) return;
    Object.defineProperty(this, "currentView", {
      value: null
    });
    this._subscribers.forEach((s) => {
      try {
        s.next(null);
      } catch (e) {
        // Log this as an uncaught error, but don't stop chain of subscribers;
        console.error("Uncaught", e);
      }
    });
  }
}
