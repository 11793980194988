import { Component, OnInit } from "@angular/core";
import { UrlService } from "src/app/services/url.service";
import { RouterLink } from "@angular/router";
import { ButtonComponent } from "../../inputs/button/button.component";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "app-maintenance",
  templateUrl: "./maintenance.component.html",
  standalone: true,
  imports: [RouterLink, ButtonComponent, TranslateModule]
})
export class MaintenanceComponent implements OnInit {
  constructor(public urlService: UrlService) {}

  ngOnInit(): void {}
}
