import { Component, Input, OnInit } from "@angular/core";
import { FormService } from "src/app/services/form.service";
import { IconService } from "src/app/services/icon.service";
import {
  DealerResponse,
  DealerWorkshopSystemResponse,
  WorkshopSystemEnum
} from "src/domain/client";
import { NgIf, NgFor } from "@angular/common";
import { IconComponent } from "../icon/icon.component";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "app-workshop-info",
  templateUrl: "./workshop-info.component.html",
  standalone: true,
  imports: [NgIf, NgFor, IconComponent, TranslateModule]
})
export class WorkshopInfoComponent implements OnInit {
  @Input() workshop: DealerResponse;
  @Input() hideServices?: boolean;

  public dealerPropertyDescription: string;
  public showDealerPropertyDescription: boolean;

  constructor(
    public formService: FormService,
    public iconService: IconService
  ) {}

  ngOnInit(): void {}

  getIconSelection(): WorkshopSystemEnum[] {
    const enumValues = Object.values(
      WorkshopSystemEnum
    ) as WorkshopSystemEnum[];

    if (
      this.formService.serviceTypeSelection.includes(
        WorkshopSystemEnum.TechUpdate
      )
    ) {
      return [
        ...enumValues.filter((s) => s === WorkshopSystemEnum.TechUpdate),
        ...enumValues.filter((s) => s !== WorkshopSystemEnum.TechUpdate)
      ];
    }

    return enumValues.filter((s) => s !== WorkshopSystemEnum.TechUpdate);
  }

  hasDealerSystem(
    sys: WorkshopSystemEnum,
    dealerSys: DealerWorkshopSystemResponse[]
  ): boolean {
    if (sys === WorkshopSystemEnum.TechUpdate)
      return this.formService.serviceTypeSelection.includes(
        WorkshopSystemEnum.TechUpdate
      );
    if (sys === WorkshopSystemEnum.Other) return true;
    return !!dealerSys.filter((s) => s.workshopSystem === sys).length;
  }

  handleDealerPropertyClicked(description: string): void {
    if (this.dealerPropertyDescription == description) {
      this.dealerPropertyDescription = null;
      this.showDealerPropertyDescription = false;
      return;
    }
    this.dealerPropertyDescription = description;
    this.showDealerPropertyDescription = true;
  }
}
