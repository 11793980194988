import { Injectable } from "@angular/core";
import { PageFormIdEnum, PageFormNameEnum } from "../classes/enums";

@Injectable({
  providedIn: "root"
})
export class MappingService {
  constructor() {}

  stepNumberToI18nString(num: number): string {
    switch (num) {
      case 1:
        return "about-vehicle";
      case 2:
        return "workshop-system-selection";
      case 3:
        return "find-dealer";
      case 4:
        return "choose-specifications";
      case 5:
        return "extra-services";
      case 6:
        return "time-selection";
      case 7:
        return "contact-information";
      case 8:
        return "booking";
      default:
        return "";
    }
  }

  stepNumberToFormName(num: number): string {
    switch (num) {
      case PageFormIdEnum.aboutVehicle:
        return PageFormNameEnum.aboutVehicle;
      case PageFormIdEnum.workshopSystemSelection:
        return PageFormNameEnum.workshopSystemSelection;
      case PageFormIdEnum.findDealer:
        return PageFormNameEnum.findDealer;
      case PageFormIdEnum.chooseSpecifications:
        return PageFormNameEnum.chooseSpecifications;
      case PageFormIdEnum.extraServices:
        return PageFormNameEnum.extraServices;
      case PageFormIdEnum.timeSelection:
        return PageFormNameEnum.timeSelection;
      case PageFormIdEnum.contactInformation:
        return PageFormNameEnum.contactInformation;
      default:
        return null;
    }
  }
}
