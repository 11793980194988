import { Component, Input } from "@angular/core";
import { UrlService } from "src/app/services/url.service";
import { RouterLink } from "@angular/router";
import { MatIcon } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "app-information-content",
  templateUrl: "./information-content.component.html",
  standalone: true,
  imports: [RouterLink, MatIcon, TranslateModule]
})
export class InformationContentComponent {
  @Input({ required: true }) title!: string;
  constructor(public urlService: UrlService) {}
}
