import { Component, Input, OnInit } from "@angular/core";
import { NgIf } from "@angular/common";
import { MatProgressSpinner } from "@angular/material/progress-spinner";

@Component({
  selector: "app-page-container",
  templateUrl: "./page-container.component.html",
  standalone: true,
  imports: [NgIf, MatProgressSpinner]
})
export class PageContainerComponent implements OnInit {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() isLoading: boolean;

  constructor() {}

  ngOnInit(): void {}
}
