import { Component, Input, OnInit } from "@angular/core";
import { expandAnimation } from "src/app/animations/expand";
import { PriceService } from "src/app/services/price.service";
import { PackageResponse, PriceTypeEnum } from "src/domain/client";
import { NgFor, NgIf } from "@angular/common";
import { PricePipe } from "../../../pipes/sharedpipes/price.pipe";
import { PriceDiffPipePipe } from "../../../pipes/price-diff-pipe.pipe";
import { PackagePricePipe } from "../../../pipes/package-price.pipe";
import { FormService } from "src/app/services/form.service";

@Component({
  selector: "app-show-proposal-pps-packages",
  templateUrl: "./show-proposal-pps-packages.component.html",
  animations: [expandAnimation],
  standalone: true,
  imports: [NgFor, NgIf, PricePipe, PriceDiffPipePipe, PackagePricePipe]
})
export class ShowProposalPpsPackagesComponent implements OnInit {
  @Input() packages: PackageResponse[];
  public PriceTypeEnum: typeof PriceTypeEnum = PriceTypeEnum;

  constructor(
    public priceService: PriceService,
    public formService: FormService
  ) {}

  ngOnInit(): void {}
}
