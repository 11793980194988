import { Component, Input, OnInit } from "@angular/core";
import { BrandEnum } from "src/app/classes/enums";
import { NgIf } from "@angular/common";
import { ImageResolverPipe } from "../../../pipes/sharedpipes/image-resolver.pipe";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "app-brand-card",
  templateUrl: "./brand-card.component.html",
  standalone: true,
  imports: [NgIf, ImageResolverPipe, TranslateModule]
})
export class BrandCardComponent implements OnInit {
  public BrandEnum: typeof BrandEnum = BrandEnum;
  @Input() brand: string;

  constructor() {}

  ngOnInit(): void {}
}
